import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { CustomRichTextComponent } from "../../shared/components/custom-rich-text/custom-rich-text.component";

@Component({
    selector: "talentbridge-help",
    templateUrl: "./help.component.html",
    styleUrls: ["./help.component.scss"],
    standalone: true,
    imports: [CustomRichTextComponent],
})
export class HelpComponent implements OnInit, OnDestroy {
    public richTextTypeID: number = CustomRichTextTypeEnum.Help;
    public currentUser: UserDto;
    canEdit: boolean;

    userSubscription: Subscription;

    constructor(private authenticationService: AuthenticationService) {}

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;

                this.canEdit = this.authenticationService.hasPermission(
                    this.currentUser,
                    PermissionEnum.CustomPageRights,
                    RightsEnum.Update
                );
            });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
    }
}
