@if (isLoading) {
  <div class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
  </div>
}

@if (!isLoading) {
  @if (!isEditing) {
    <div
      id="displayContent"
            [ngClass]="
                userCanEdit
                    ? 'customRichTextContent'
                    : 'customRichTextContentNoEdit'
            "
      >
      @if (userCanEdit) {
        <esa-material-button
          class="hoverEditButton"
          type="clear"
          label="Edit"
          icon="edit"
          (click)="enterEdit()"
          data-cy="edit"
        ></esa-material-button>
      }
      @if (!isEmptyContent) {
        <div [innerHTML]="customRichTextContent"></div>
      }
      @if (isEmptyContent) {
        <div class="hoverEmptySpace"></div>
      }
    </div>
  }
  @if (isEditing) {
    <editor
      [id]="editorId"
      #tinyMceEditor
      [init]="tinyMceConfig"
      [(ngModel)]="editedContent"
    ></editor>
    <div class="actions-bar flex-end" style="margin-top: 1rem;">
      <esa-material-button
        type="primary"
        icon="save"
        label="Save"
        (click)="saveEdit()"
        data-cy="save"
        style="margin-right: 1rem;"
      ></esa-material-button>
      <esa-material-button
        type="clear"
        icon="cancel"
        label="Cancel"
        (click)="cancelEdit()"
        data-cy="cancel"
      ></esa-material-button>
    </div>
  }
}
