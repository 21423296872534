import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AsyncPipe } from '@angular/common';
import { AdminConfigurationDto } from 'src/app/shared/generated/model/admin-configuration-dto';
import { AdminConfigurationService } from 'src/app/shared/generated/api/admin-configuration.service';
import { ConfigurationFormComponent } from '../shared/configuration-form/configuration-form.component';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';

@Component({
    selector: 'talentbridge-configuration-detail',
    templateUrl: './configuration-detail.component.html',
    styleUrls: ['./configuration-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        ConfigurationFormComponent,
        EsaMaterialButtonComponent
    ],
})
export class ConfigurationDetailComponent implements OnInit, OnDestroy {
    public configuration$: Observable<AdminConfigurationDto>;
    public currentUser: UserDto;
    public editMode = false;
    user: Subscription;

    constructor(
        private configurationService: AdminConfigurationService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.configuration$ = this.configurationService.adminConfigurationGet().pipe(
            map((data) => {
                this.cdr.markForCheck();
                return data;
            })
        );
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
          this.currentUser = result;
          this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.AdminConfigurationRights, RightsEnum.Update) && !this.editMode;
    }
    
    cancelEdit() {
        this.editMode = false;
    }

    enableEditMode() {
        this.editMode = !this.editMode;
    }

}
