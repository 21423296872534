<form class="form grid-12" #clientForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff 
            name="ClientNumber" 
            [editMode]="false" 
            label="Client Number"
            type="text"
            [(ngModel)]="client.ClientNumber">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="Name" 
            [editMode]="false" 
            label="Client Name"
            type="text" 
            [(ngModel)]="client.Name">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="Market" 
            [editMode]="false" 
            label="Market"
            type="text" 
            [(ngModel)]="client.Market">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="ClientType" 
            [editMode]="false" 
            label="Type"
            type="text" 
            [(ngModel)]="client.ClientType">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="Status" 
            [editMode]="false" 
            label="Status"
            type="text" 
            [(ngModel)]="client.Status">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="Address1" 
            [editMode]="false" 
            label="Address 1"
            type="text" 
            [(ngModel)]="client.Address1">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="Address2" 
            [editMode]="false" 
            label="Address 2"
            type="text" 
            [(ngModel)]="client.Address2">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="Address3" 
            [editMode]="false" 
            label="Address 3"
            type="text" 
            [(ngModel)]="client.Address3">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="City" 
            [editMode]="false" 
            label="City"
            type="text" 
            [(ngModel)]="client.City">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="State" 
            [editMode]="false" 
            label="State"
            type="text" 
            [(ngModel)]="client.State">
        </esa-material-ff>
    </div>
    <div class="g-col-6">
        <esa-material-ff 
            name="ZipCode" 
            [editMode]="false" 
            label="Zip Code"
            type="text" 
            [(ngModel)]="client.ZipCode">
        </esa-material-ff>
    </div>
</form>
