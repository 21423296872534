import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
  Control, LeafletEvent,
  Map,
  MapOptions
} from 'leaflet';
import * as L from 'leaflet';

@Component({
  selector: 'map',
  standalone: true,
  imports: [],
  template: `
    <div [id]="mapID" [style.height]="mapHeight">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
  ]
})
export class MapComponent implements AfterViewInit, OnDestroy {
  public mapID: string = crypto.randomUUID();
  public map: Map;
  public tileLayers: { [key: string]: any } = {
    'Aerial': L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Aerial',
    }),
    'Street': L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Street',
    }),
    'Terrain': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Terrain',
    })
  }
  public layerControl: Control.Layers;
  @Input() mapHeight: string = '500px';
  @Input() showLayerControl: boolean = true;
  @Output() onMapLoad: EventEmitter<MapInitEvent> = new EventEmitter();

  constructor() {
  }

  ngAfterViewInit(): void {

    const mapOptions: MapOptions = {
      minZoom: 1,
      maxZoom: 44,
      layers: [
        this.tileLayers.Aerial,
      ],
      fullscreenControl: true,
      gestureHandling: true
    } as MapOptions;

    this.map = L.map(this.mapID, mapOptions);

    this.layerControl = new Control.Layers(this.tileLayers, null, { collapsed: true });
    if (this.showLayerControl) {
      this.layerControl.addTo(this.map);
    }

    this.map.on('load', (event: LeafletEvent) => {
      this.onMapLoad.emit(new MapInitEvent(this.map, this.layerControl, this.mapID));
    });

    // Set the map view to the continental US
    this.map.fitBounds([[24.396308, -125.000000], [49.3457868, -66.93457]], null);

  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.off();
      this.map.remove();
      this.map = null;
    }
  }

}

export class MapInitEvent {
  public map: Map;
  public layerControl: any;
  public mapID: string;
  constructor(map: Map, layerControl: any, mapID?: string) {
    this.map = map;
    this.layerControl = layerControl;
    this.mapID = mapID;
  }
}