import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ClientService } from "src/app/shared/generated/api/client.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { CsvDownloadButtonComponent } from "../../../shared/components/csv-download-button/csv-download-button.component";
import { ClearGridFiltersButtonComponent } from "../../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";

@Component({
    selector: "talentbridge-client-list",
    templateUrl: "./client-list.component.html",
    styleUrls: ["./client-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ClearGridFiltersButtonComponent,
        CsvDownloadButtonComponent,
        AgGridAngular,
    ],
})
export class ClientListComponent implements OnInit {
    @ViewChild("ClientsGrid") clientsGrid: AgGridAngular;

    public currentUser: UserDto;
    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    pinnedBottomRowData;
    currentUserRequest: Subscription;
    getClientsRequest: Subscription;

    constructor(
        private clientService: ClientService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
          };
    }

    ngOnInit(): void {
        this.currentUserRequest = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.columnDefs = [
                    {
                        headerName: "Client Name",
                        headerTooltip: "Client Name",
                        valueGetter: function (params: any) {
                            return params.node.rowPinned
                                ? "Total: " + params.data.ClientTotal
                                : {
                                    LinkValue: params.data.ClientID,
                                    LinkDisplay: params.data.Name,
                                };
                        },
                        cellRendererSelector: (params) => {
                            return params.node.rowPinned
                                ? {
                                    component: CustomPinnedRowRendererComponent,
                                    params:  { filter: true },
                                }
                                : {
                                    component: LinkRendererComponent,
                                    params: { inRouterLink: "/clients/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.node.rowPinned ? null : params.data.Name;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "Name",
                    },
                    {
                        headerName: "Client Number",
                        field: "ClientNumber",
                        flex: 2,
                        tooltipField: "ClientNumber",
                        wrapText: true,
                    },
                    {
                        headerName: "Market",
                        field: "Market",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "Market",
                        },
                        tooltipField: "Market",
                    },
                    {
                        headerName: "Type",
                        field: "ClientType",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "ClientType",
                        },
                        tooltipField: "ClientType",
                    },
                    {
                        headerName: "Status",
                        field: "Status",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "Status",
                        },
                        tooltipField: "Status",
                    },
                ];
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.currentUserRequest.unsubscribe();
        this.getClientsRequest.unsubscribe();
    }

    onClientsGridReady(gridEvent) {
        this.clientsGrid.api.showLoadingOverlay();

        this.getClientsRequest = this.clientService
            .clientsGet()
            .subscribe((results) => {
                this.rowData = results;
                this.pinnedBottomRowData = [
                    {
                        ClientTotal: this.rowData.length,
                    },
                ];
                this.clientsGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                ClientTotal: gridEvent.api.getDisplayedRowCount(),
            },
        ]);
    }
}
