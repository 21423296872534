import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { QualificationDto } from '../shared/generated/model/qualification-dto';
import { QualificationUpsertDto } from '../shared/generated/model/qualification-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
    providedIn: 'root'
})
export class CurrentQualificationService {
    currentQualification$ = new BehaviorSubject<QualificationDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentQualification(qualification: QualificationDto): void {
        this.currentQualification$.next(qualification);
    }

    getCurrentQualification(): Observable<any> {
        return this.currentQualification$.asObservable();
    }

    createQualificationDto(qualificationDto: QualificationDto) {
        const newQualificationUpsertDto = new QualificationUpsertDto({
            QualificationTypeID: qualificationDto?.QualificationType?.QualificationTypeID,
            SubServiceID: qualificationDto?.SubService?.SubServiceID,
            Name: qualificationDto?.Name,
            Description: qualificationDto?.Description,
        });
        return newQualificationUpsertDto;
    }

    canEditCurrentQualification(currentUser: UserDto, qualification: QualificationDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.QualificationRights, RightsEnum.Update);
    }
}
