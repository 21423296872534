import {
    Component,
    OnInit,
    Input,
    ChangeDetectorRef,
    ViewChild,
    ChangeDetectionStrategy,
    OnDestroy,
} from "@angular/core";
import { Alert } from "../../models/alert";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { FieldDefinitionService } from "src/app/shared/generated/api/field-definition.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { AlertService } from "../../services/alert.service";
import { AlertContext } from "../../models/enums/alert-context.enum";
import { FieldDefinitionTypeEnum } from "../../generated/enum/field-definition-type-enum";
import { FieldDefinitionDto } from "../../generated/model/field-definition-dto";
import { PermissionEnum } from "../../generated/enum/permission-enum";
import { RightsEnum } from "../../models/enums/rights.enum";
import { Observable, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PopperDirective } from "../../directives/popper.directive";
import { MatIcon } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";
import { EditorComponent, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { AsyncPipe } from "@angular/common";

@Component({
    selector: "field-definition",
    templateUrl: "./field-definition.component.html",
    styleUrls: ["./field-definition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    EsaMaterialButtonComponent,
    EditorComponent,
    FormsModule,
    MatIcon,
    PopperDirective,
    AsyncPipe
], 
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'assets/tinymce/tinymce.min.js' }
    ]
})
export class FieldDefinitionComponent implements OnInit, OnDestroy {
    @Input() fieldDefinitionType: string;
    @Input() labelOverride: string;
    @Input() iconOnly: boolean = false;
    @Input() popperOptions?: any = {
        placement: "top",
        strategy: "absolute",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: ({ placement, reference, popper }) => {
                        return [0, 5];
                    },
                },
            },
        ],
    };

    @ViewChild("p") public popover: any;
    @ViewChild("popContent") public content: any;

    fieldDefinition$: Observable<FieldDefinitionDto>;
    public fieldDefinition: FieldDefinitionDto;
    public isLoading: boolean = true;
    public isEditing: boolean = false;
    public emptyContent: boolean = false;

    public editedContent: string;
    public editor;

    currentUser: UserDto;
    userSubscription: Subscription;

    constructor(
        private fieldDefinitionService: FieldDefinitionService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;
                this.cdr.markForCheck();
            });

        this.fieldDefinition$ = this.fieldDefinitionService
            .fieldDefinitionsFieldDefinitionTypeIDGet(
                FieldDefinitionTypeEnum[this.fieldDefinitionType]
            )
            .pipe(
                map((result) => {
                    this.loadFieldDefinition(result);
                    return result;
                })
            );
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription.unsubscribe();
    }

    public getLabelText() {
        return this.labelOverride !== null && this.labelOverride !== undefined
            ? this.labelOverride
            : this.fieldDefinition.FieldDefinitionType.DisplayName;
    }

    public showEditButton(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.FieldDefinitionRights,
            RightsEnum.Update
        );
    }

    public enterEdit(): void {
        this.editedContent = this.fieldDefinition.FieldDefinitionValue ?? "";
        this.isEditing = true;
        this.cdr.markForCheck();
    }

    public cancelEdit(): void {
        this.isEditing = false;
        this.cdr.markForCheck();
    }

    public saveEdit(): void {
        this.isEditing = false;
        this.isLoading = true;
        this.fieldDefinition.FieldDefinitionValue = this.editedContent;
        this.cdr.markForCheck();
        this.fieldDefinition$ = this.fieldDefinitionService
            .fieldDefinitionsFieldDefinitionTypeIDPut(
                this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeID,
                this.fieldDefinition
            )
            .pipe(
                map((result) => {
                    this.loadFieldDefinition(result);
                    return result;
                }),
                catchError((err: any) => {
                    this.isLoading = false;
                    this.alertService.pushAlert(
                        new Alert(
                            "There was an error updating the field definition",
                            AlertContext.Danger,
                            true
                        )
                    );
                    return err;
                })
            );
    }

    private loadFieldDefinition(fieldDefinition: FieldDefinitionDto) {
        this.fieldDefinition = fieldDefinition;
        this.emptyContent =
            fieldDefinition.FieldDefinitionValue?.length > 0 ? false : true;
        this.isLoading = false;
        this.cdr.markForCheck();
    }
}
