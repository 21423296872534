@if (params.value.LinkDisplay && !params.value.ChildRoute) {
  <a
    [routerLink]="[params.inRouterLink, params.value.LinkValue]"
    [className]="params.value.CssClasses">{{ params.value.LinkDisplay }}</a>
}
@if (params.value.LinkDisplay && params.value.ChildRoute) {
  <a
    [routerLink]="[params.inRouterLink, params.value.LinkValue, params.value.ChildRoute]"
    [queryParams]="params.value.QueryParams"
    [className]="params.value.CssClasses">{{ params.value.LinkDisplay }}</a>
}
