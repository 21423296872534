<div class="detail-view">
 
  <mat-accordion>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> Qualification Employee Map</mat-panel-title>
      </mat-expansion-panel-header>

      <map #MapComponent (onMapLoad)="mapInit($event)">
        @if (map) {
          @for (e of filteredZipsWithGeoData; track $index) {
            <map-marker 
              #mapMarker
              [latitude]="e.Latitude" 
              [longitude]="e.Longitude" 
              [label]="e.ZipCode" 
              [map]="map">
              <!-- NOTE: CG: We cant select just one user for a marker here punting. (onClick)="onMarkerClick($event, e)"> -->
            </map-marker>
          } 
        }
      </map>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="filter-btn-group align-right mb-3">
    <talentbridge-clear-grid-filters-button class="mr-2" [grid]="employeeGrid"></talentbridge-clear-grid-filters-button>
    <talentbridge-csv-download-button [grid]="employeeGrid" fileName="employees"></talentbridge-csv-download-button>
  </div>

  <div>
    <div class="table-responsive" style="height: 600px">
      <ag-grid-angular
        #EmployeeGrid
        style="width: 100%; height: 100%"
        class="ag-theme-balham"
        [rowData]="rowData"
        [pinnedBottomRowData]="pinnedBottomRowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [suppressMenuHide]="true"
        rowSelection="single"
        (gridReady)="onGridReady($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (filterChanged)="onFilterChanged($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
