import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { SubServiceDto } from '../shared/generated/model/sub-service-dto';
import { SubServiceUpsertDto } from '../shared/generated/model/sub-service-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
    providedIn: 'root'
})
export class CurrentSubServiceService {
    currentSubService$ = new BehaviorSubject<SubServiceDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentSubService(subService: SubServiceDto): void {
        this.currentSubService$.next(subService);
    }

    getCurrentSubService(): Observable<any> {
        return this.currentSubService$.asObservable();
    }

    createSubServiceDto(subServiceDto: SubServiceDto) {
        const newSubServiceUpsertDto = new SubServiceUpsertDto({
            ServiceCategoryID: subServiceDto?.ServiceCategory?.ServiceCategoryID,
            Name: subServiceDto?.Name,
            Description: subServiceDto?.Description,
        });
        return newSubServiceUpsertDto;
    }

    canEditCurrentSubService(currentUser: UserDto, subService: SubServiceDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.QualificationRights, RightsEnum.Update);
    }
}
