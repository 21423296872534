<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Employees</h1>
        </div>
    </div>
    
    <div class="filter-btn-group align-right mb-3">
        <talentbridge-clear-grid-filters-button class="mr-2" [grid]="employeesGrid"></talentbridge-clear-grid-filters-button>
        <talentbridge-csv-download-button [grid]="employeesGrid" fileName="employees"></talentbridge-csv-download-button>
    </div>

    <div>
        <div class="table-responsive" style="height: 800px">
            <ag-grid-angular
                #EmployeesGrid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [rowData]="rowData"
                [pinnedBottomRowData]="pinnedBottomRowData" 
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                (gridReady)="onEmployeesGridReady($event)"
                (filterChanged)="onFilterChanged($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>