<div class="header-wrapper">
  <div class="employee-title">
    <h3>Summary</h3>
  </div>
</div>

<div class="detail-view">
  @if (employee$ | async; as emp) {
    <div class="actions-bar">
      @if (canEdit(emp)) {
        <esa-material-button type="clear" icon="edit" (click)="enableEditMode()"> </esa-material-button>
      }
    </div>
    <talentbridge-employee-form
      #updateEmployeeForm
      [userID]="emp.UserID"
      [employee]="emp"
      [editMode]="editMode"
      (formSubmitted)="save($event)"
      (cancelEditModeChange)="cancelEdit()">
    </talentbridge-employee-form>
  }
</div>
