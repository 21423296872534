import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CurrentQualificationService } from 'src/app/services/current-qualification.service';
import { QualificationService } from 'src/app/shared/generated/api/qualification.service';
import { QualificationTypeService } from 'src/app/shared/generated/api/qualification-type.service';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { QualificationUpsertDto } from 'src/app/shared/generated/model/qualification-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { forkJoin } from 'rxjs';
import { ServiceLineService } from 'src/app/shared/generated/api/service-line.service';
import { ServiceCategoryService } from 'src/app/shared/generated/api/service-category.service';
import { SubServiceService } from 'src/app/shared/generated/api/sub-service.service';


@Component({
  selector: 'talentbridge-qualification-form',
  templateUrl: './qualification-form.component.html',
  styleUrls: ['./qualification-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EsaMaterialButtonComponent,
    EsaMaterialFormFieldComponent,
    FormsModule
  ]
})
export class QualificationFormComponent implements OnInit {
  @ViewChild("qualificationForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() qualificationID: number;
  @Input() qualification: QualificationDto;
  @Input() editMode: boolean;

  qualificationUpsertDto: QualificationUpsertDto;
  selectedServiceLineID: number;
  selectedServiceCategoryID: number

  public serviceLines: any;
  public serviceCategories: any;
  public subServices: any;
  public qualificationTypes: any;
  
  public serviceCategoriesFiltered: any;
  public subServicesFiltered: any;

  constructor(private currentQualificationService: CurrentQualificationService,
    private qualificationService: QualificationService,
    private qualificationTypeService: QualificationTypeService,
    private subServiceService: SubServiceService,
    private serviceCategoryService: ServiceCategoryService,
    private serviceLineService: ServiceLineService,    
    private alertService: AlertService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    forkJoin([
      this.serviceLineService.serviceLinesGet(), 
      this.serviceCategoryService.serviceCategoriesGet(), 
      this.subServiceService.subServicesGet(),
      this.qualificationTypeService.qualificationTypesGet()])
      .subscribe(([serviceLines, serviceCategories, subServices, qualificationTypes]) => {  
        this.serviceLines = serviceLines; 
        this.serviceCategories = serviceCategories; 
        this.subServices = subServices; 
        this.qualificationTypes = qualificationTypes; 
        this.setForm();
      });
  }

  setForm() {
    this.qualificationUpsertDto = this.currentQualificationService.createQualificationDto(this.qualification);
    this.selectedServiceLineID = this.qualification.SubService?.ServiceCategory?.ServiceLine?.ServiceLineID;
    this.selectedServiceCategoryID = this.qualification.SubService?.ServiceCategory?.ServiceCategoryID;

    this.filterServiceLine();
    this.filterServiceCategory();
    this.cdr.detectChanges();
  }

  saveForm(form: NgForm) {
    if(this.qualificationID) {
      this.qualificationService.qualificationsQualificationIDPut(this.qualificationID, this.qualificationUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The qualification was successfully updated.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
    else {
      this.qualificationService.qualificationsPost(this.qualificationUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The qualification was successfully created.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  filterServiceLine() {
    this.serviceCategoriesFiltered = this.serviceCategories.filter((serviceCategory) => serviceCategory.ServiceLine.ServiceLineID === this.selectedServiceLineID);
    if (this.serviceCategoriesFiltered.filter((serviceCategory) => serviceCategory.ServiceCategoryID === this.selectedServiceCategoryID).length === 0) {
      this.selectedServiceCategoryID = null;
      this.qualificationUpsertDto.SubServiceID = null;
      this.subServicesFiltered = [];
     }
  }

  filterServiceCategory() {
    this.subServicesFiltered = this.subServices.filter((subService) => subService.ServiceCategory.ServiceCategoryID === this.selectedServiceCategoryID);
    if (this.subServicesFiltered.filter((subService) => subService.SubServiceID === this.qualificationUpsertDto.SubServiceID).length === 0) {
      this.qualificationUpsertDto.SubServiceID = null;
    }
  }
    
}