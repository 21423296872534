import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RoleEnum } from "src/app/shared/generated/enum/role-enum";
import { environment } from "src/environments/environment";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { Router, RouterLink } from "@angular/router";
import { Subscription } from "rxjs";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { CustomRichTextComponent } from "../../../shared/components/custom-rich-text/custom-rich-text.component";


@Component({
    selector: "app-home-index",
    templateUrl: "./home-index.component.html",
    styleUrls: ["./home-index.component.scss"],
    standalone: true,
    imports: [
    RouterLink,
    CustomRichTextComponent,
    EsaMaterialButtonComponent
],
})
export class HomeIndexComponent implements OnInit, OnDestroy {
    currentUser: UserDto;
    userCanEditCustomText: boolean;
    userRoleIsDisabled: boolean;
    userIsUnassigned: boolean;

    public richTextTypeID: number = CustomRichTextTypeEnum.Homepage;

    routeSubscription: Subscription;
    userSubscription: Subscription;
    platformLongName: string;

    constructor(
        public authenticationService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.platformLongName = environment.platformLongName;
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;
                this.userCanEditCustomText =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.CustomRichTextRights,
                        RightsEnum.Update
                    );
                this.userRoleIsDisabled = this.currentUser
                    ? this.currentUser.Role.RoleID === RoleEnum.NoAccess &&
                      !this.currentUser.IsActive
                    : false;
                this.userIsUnassigned = this.currentUser ? this.currentUser.Role.RoleID === RoleEnum.NoAccess && this.currentUser.IsActive : false;
            });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
    }

    login(): void {
        this.authenticationService.login();
    }

    requestSupport() {
        this.router.navigateByUrl("/help");
    }
}
