<div class="popover">
  @if (title) {
    <h3 class="popover-header">
      @if (titleIsTemplate) {
        <ng-template
          [ngTemplateOutlet]="titleTemplate"
        ></ng-template>
      } @else {
        {{titleString}}
      }
    </h3>
  }
  <div class="popover-body">
    <ng-content></ng-content>
    <ng-template
      [ngTemplateOutlet]="context"
    ></ng-template>
  </div>
</div>
<div class="popover-arrow" data-popper-arrow></div>