import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectFormComponent } from '../shared/project-form/project-form.component';
import { CurrentProjectService } from 'src/app/services/current-project.service';
import { ProjectUpsertDto } from 'src/app/shared/generated/model/project-upsert-dto';

@Component({
    selector: 'talentbridge-project-create',
    templateUrl: './project-create.component.html',
    styleUrls: ['./project-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ProjectFormComponent],
})
export class ProjectCreateComponent implements OnInit, OnDestroy {        
  @ViewChild("createNewProjectForm", { read: ProjectFormComponent }) form: ProjectFormComponent;

  public project: ProjectUpsertDto = new ProjectUpsertDto();
  public projectUpsertDto: ProjectUpsertDto;
  private projectResponse: any;

  editMode = true;

  constructor(
      private currentProjectService: CurrentProjectService,
      private router: Router,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.projectUpsertDto = this.currentProjectService.createProjectDto(this.project);
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  save(result): void {
    if (!!result) {
        this.projectResponse = result;
        this.router.navigateByUrl(`/projects/${result.ProjectID}`);
    } else {
        this.cdr.detectChanges();
    }
  }

  cancelEdit() {
      this.router.navigateByUrl("/projects");
  }

  canExit() {
      if (this.projectResponse) {
          return true;
      } else {
          return JSON.stringify(this.projectUpsertDto) === JSON.stringify(this.form.projectUpsertDto);
      }
  }
}
