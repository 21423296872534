<div class="main">
  @if (isLoading) {
    <div class="text-center">
      <span class="fa fa-spinner loading-spinner"></span>
    </div>
  }

  @if (customPage$ | async; as customPage) {
    <div class="page-header">
      <h1 class="page-title">{{ customPage.DisplayName }}</h1>
    </div>
    @if (!isLoading) {
      <div class="edit-panel pb-3">
        @if (!isEditing) {
          @if (showEditButton()) {
            <div class="customPageContent" style="position: relative; min-height: 60px;">
              <esa-material-button class="hoverEditButton" icon="edit" type="clear" (click)="enterEdit()"></esa-material-button>
              @if (!isEmptyContent) {
                <div class="copy copy-2 custom-content-copy" [innerHtml]="customPageContent"></div>
              }
              @if (isEmptyContent) {
                <div class="hoverEmptySpace"></div>
              }
            </div>
          }
          @if (!showEditButton()) {
            <div [innerHtml]="customPageContent"></div>
          }
        }
        @if (isEditing) {
          <div>
            <editor #tinyMceEditor
              [init]="tinyMceConfig"
              [(ngModel)]="editedContent"
            ></editor>
            <div style="float:right;" >
              <esa-material-button type="primary" label="Save" (click)="saveEdit()"></esa-material-button>
              <esa-material-button type="clear" label="Cancel" (click)="cancelEdit()"></esa-material-button>
            </div>
          </div>
        }
      </div>
    }
  }
</div>