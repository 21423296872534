import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentQualificationService } from 'src/app/services/current-qualification.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { QualificationFormComponent } from '../../shared/qualification-form/qualification-form.component';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { QualificationUpsertDto } from 'src/app/shared/generated/model/qualification-upsert-dto';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'talentbridge-qualification-detail-summary',
    templateUrl: './qualification-detail-summary.component.html',
    styleUrls: ['./qualification-detail-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    QualificationFormComponent,
    EsaMaterialButtonComponent,
    AsyncPipe
]
})
export class QualificationDetailSummaryComponent implements OnInit, OnDestroy {
  @ViewChild("updateQualificationForm", { read: QualificationFormComponent }) form: QualificationFormComponent;

    public qualification$: Observable<any>;
    public currentUser: UserDto;
    public editMode = false;
    public originalQualificationUpsertDto: QualificationUpsertDto;

    currentUserSubscription: Subscription;

    constructor(
        private currentQualificationService: CurrentQualificationService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.qualification$ = this.currentQualificationService.getCurrentQualification().pipe(
            map((data) => {
                this.originalQualificationUpsertDto = this.currentQualificationService.createQualificationDto(data);
                this.cdr.markForCheck();
                return data;
            })
        );
    }

    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }

    refreshQualification(result: any) {
        this.editMode = false;
        this.currentQualificationService.setCurrentQualification(result);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save(result) {
        this.refreshQualification(result);
    }

    canEdit(qualification: QualificationDto): boolean {
        return this.currentQualificationService.canEditCurrentQualification(this?.currentUser, qualification) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.originalQualificationUpsertDto) === JSON.stringify(this.form.qualificationUpsertDto);
        } else {
            return true;
        }
    }
}
