import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BASE_PATH } from "../../generated/variables";

@Injectable({
    providedIn: "root",
})
export class FileService {
    constructor(private http: HttpClient, @Inject(BASE_PATH) private basePath: string) {}

    downloadFile(fileRoute: string): Observable<any> {
        const route = `${this.getApiUrl()}/${fileRoute}/download`;

        const headers = new HttpHeaders();
        const req = new HttpRequest("GET", route, { headers, responseType: "blob" });
        return this.http.request(req).pipe(
            map((response: HttpResponse<Blob>) => {
                return response.body;
            })
        );
    }

    uploadFiles(fileRoute: string, filesToUpload: any[]): Observable<any> {
        const route = `${this.getApiUrl()}/${fileRoute}`;
        
        const formData = new FormData();
        filesToUpload.forEach((file) => {
            formData.append(file.name, file);
        });

        const headers = new HttpHeaders();
        const req = new HttpRequest("POST", route, formData, { headers, responseType: "json" });
        return this.http.request(req);
    }

    private getApiUrl() {
        return this.basePath;
    }
}
