<div class="list-view">
  <div class="page-header">
    <div class="actions-bar flex-between">
      <h1 class="page-title">Users</h1>
      @if (canCreate) {
        <esa-material-button type="primary" label="Invite User" aria-label="Invite New User" (click)="inviteUser()"></esa-material-button>
      }
    </div>

    <div class="copy copy-2">
      <p>A list of users is shown in the grid below.</p>
    </div>
  </div>

  <div class="all-users">
    <div class="user-actions flex-end pb-2">
      <talentbridge-clear-grid-filters-button class="mr-3" [grid]="usersGrid"></talentbridge-clear-grid-filters-button>
      <talentbridge-csv-download-button [grid]="usersGrid" fileName="users"></talentbridge-csv-download-button>
    </div>

    <div class="table-responsive" style="height: 800px;">
      <ag-grid-angular
        #usersGrid
        style="width: 100%; height: 100%;"
        class="ag-theme-balham"
        [rowData]="rowData"
        [pinnedBottomRowData]="pinnedBottomRowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [suppressMenuHide]="true"
        (gridReady)="onUsersGridReady($event)"
        (filterChanged)="onFilterChanged($event)">
      </ag-grid-angular>
    </div>
  </div>

  <hr class="section-spacer" />

  <div class="unassigned-users">
    <div class="section-header">
      <h2 class="section-title">Unassigned Users</h2>

      <div class="copy copy-2">
        @if (users && unassignedUsers.length) {
          <p>
            This is a list of all users who are awaiting account configuration. Assign the user a role. If the account was created in error, edit the record and save it with a role of No Acess to remove them from this list.
          </p>
        }

        @if (users && !unassignedUsers.length) {
          <p>
            Congratulations! All users have been assigned a role.
          </p>
        }
      </div>

      @if (users && unassignedUsers.length) {
        <div class="mt-4">
          <div class="table-responsive" style="height: 300px;">
            <ag-grid-angular
              #unassignedUsersGrid
              style="width: 100%; height: 100%;"
              class="ag-theme-balham"
              [rowData]="unassignedUsers"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef">
            </ag-grid-angular>
          </div>
        </div>
      }
    </div>
  </div>
</div>