import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { EmployeeService } from "src/app/shared/generated/api/employee.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { CsvDownloadButtonComponent } from "../../../shared/components/csv-download-button/csv-download-button.component";
import { ClearGridFiltersButtonComponent } from "../../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { LinkRendererWithImageComponent } from "src/app/shared/components/ag-grid/link-renderer-with-image/link-renderer-with-image.component";
import { environment } from "src/environments/environment";
import { UserClaimsDto } from "src/app/shared/generated/model/user-claims-dto";

@Component({
    selector: "talentbridge-employee-list",
    templateUrl: "./employee-list.component.html",
    styleUrls: ["./employee-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ClearGridFiltersButtonComponent,
        CsvDownloadButtonComponent,
        AgGridAngular,
    ],
})
export class EmployeeListComponent implements OnInit {
    @ViewChild("EmployeesGrid") employeesGrid: AgGridAngular;

    public currentUser: UserClaimsDto;
    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    pinnedBottomRowData;
    user: Subscription;
    getEmployeeRequest: Subscription;

    constructor(
        private employeeService: EmployeeService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
          };
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.columnDefs = [
                    {
                        headerName: "Employee Name",
                        headerTooltip: "Employee Name",
                        valueGetter: function (params: any) {
                            return params.node.rowPinned
                                ? "Total: " + params.data.EmployeeTotal
                                : {
                                    LinkValue: params.data.UserID,
                                    LinkDisplay: params.data.FullName,
                                    ImageSrc: `${environment.mainAppApiUrl}/users/${params.data.UserID}/photoThumbnail?uid=${result.UserGuid}&secure=${result.FileAccessToken}`
                                };
                        },
                        cellRendererSelector: (params) => {
                            return params.node.rowPinned
                                ? {
                                    component: CustomPinnedRowRendererComponent,
                                    params:  { filter: true },
                                }
                                : {
                                    component: LinkRendererWithImageComponent,
                                    params: { inRouterLink: "/employees/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.node.rowPinned ? null : params.data.FullName;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "FullName",
                    },
                    {
                        headerName: "Job Title",
                        field: "JobTitle",
                        flex: 2,
                        tooltipField: "JobTitle",
                        wrapText: true,
                    },
                    {
                        headerName: "Department",
                        field: "Department",
                        flex: 2,
                        tooltipField: "Department",
                        wrapText: true,
                    },
                    {
                        headerName: "Email",
                        field: "Email",
                        flex: 2,
                        tooltipField: "Email",
                        wrapText: true,
                    },
                    {
                        headerName: "Phone",
                        field: "$Phone",
                        flex: 1,
                        tooltipField: "$Phone",
                        wrapText: true,
                    },
                    {
                        headerName: "Office Location",
                        field: "OfficeLocation",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "OfficeLocation",
                        },
                        tooltipField: "OfficeLocation",
                        wrapText: true,
                    },
                    {
                        headerName: "Zip Code",
                        field: "ZipCode",
                        flex: 1,
                        tooltipField: "ZipCode",
                        wrapText: true,
                    },
                ];
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.user?.unsubscribe();
        this.getEmployeeRequest?.unsubscribe();
    }

    onEmployeesGridReady(gridEvent) {
        this.employeesGrid.api.showLoadingOverlay();

        this.getEmployeeRequest = this.employeeService
            .employeesGet()
            .subscribe((results) => {
                this.rowData = results.filter((employee) => {
                    return employee.IsActive;
                }).map((emp) => {
                    emp["$Phone"] = emp.BusinessPhone ? emp.BusinessPhone : emp.MobilePhone;
                    return emp;
                });
                this.pinnedBottomRowData = [
                    {
                        EmployeeTotal: this.rowData.length,
                    },
                ];
                this.employeesGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                EmployeeTotal: gridEvent.api.getDisplayedRowCount(),
            },
        ]);
    }
}
