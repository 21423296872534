import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CurrentSubServiceService } from 'src/app/services/current-sub-service.service';
import { SubServiceService } from 'src/app/shared/generated/api/sub-service.service';
import { SubServiceDto } from 'src/app/shared/generated/model/sub-service-dto';
import { SubServiceUpsertDto } from 'src/app/shared/generated/model/sub-service-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { forkJoin } from 'rxjs';
import { ServiceLineService } from 'src/app/shared/generated/api/service-line.service';
import { ServiceCategoryService } from 'src/app/shared/generated/api/service-category.service';

@Component({
  selector: 'talentbridge-sub-service-form',
  templateUrl: './sub-service-form.component.html',
  styleUrls: ['./sub-service-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EsaMaterialButtonComponent,
    EsaMaterialFormFieldComponent,
    FormsModule
  ]
})
export class SubServiceFormComponent implements OnInit {
  @ViewChild("subServiceForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() subServiceID: number;
  @Input() subService: SubServiceDto;
  @Input() editMode: boolean;

  subServiceUpsertDto: SubServiceUpsertDto;
  selectedServiceLineID: number;

  public serviceLines: any;
  public serviceCategories: any;
  
  public serviceCategoriesFiltered: any;

  constructor(private currentSubServiceService: CurrentSubServiceService,
    private subServiceService: SubServiceService,
    private serviceCategoryService: ServiceCategoryService,
    private serviceLineService: ServiceLineService,    
    private alertService: AlertService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    forkJoin([
      this.serviceLineService.serviceLinesGet(), 
      this.serviceCategoryService.serviceCategoriesGet()])
      .subscribe(([serviceLines, serviceCategories]) => {  
        this.serviceLines = serviceLines; 
        this.serviceCategories = serviceCategories; 
        this.setForm();
      });
  }

  setForm() {
    this.subServiceUpsertDto = this.currentSubServiceService.createSubServiceDto(this.subService);
    this.selectedServiceLineID = this.subService.ServiceCategory?.ServiceLine?.ServiceLineID;

    this.filterServiceLine();
    this.cdr.detectChanges();
  }

  saveForm(form: NgForm) {
    if(this.subServiceID) {
      this.subServiceService.subServicesSubServiceIDPut(this.subServiceID, this.subServiceUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The sub-service was successfully updated.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
    else {
      this.subServiceService.subServicesPost(this.subServiceUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The sub-service was successfully created.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  filterServiceLine() {
    this.serviceCategoriesFiltered = this.serviceCategories.filter((serviceCategory) => serviceCategory.ServiceLine.ServiceLineID === this.selectedServiceLineID);
    if (this.serviceCategoriesFiltered.filter((serviceCategory) => serviceCategory.ServiceCategoryID === this.subServiceUpsertDto.ServiceCategoryID).length === 0) {
      this.subServiceUpsertDto.ServiceCategoryID = null;
    }
  } 
}