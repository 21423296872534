import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { ProjectSummaryDto } from "src/app/shared/generated/model/project-summary-dto";
import { CsvDownloadButtonComponent } from "../../../shared/components/csv-download-button/csv-download-button.component";
import { ClearGridFiltersButtonComponent } from "../../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { MatIconModule } from "@angular/material/icon";
import * as L from 'leaflet';
import { MapComponent, MapInitEvent as MapInitEvent } from 'src/app/shared/components/map/map.component';
import { MapMarkerComponent } from 'src/app/shared/components/map/map-marker/map-marker.component';
import { MatExpansionModule } from "@angular/material/expansion";

@Component({
    selector: "talentbridge-project-list",
    templateUrl: "./project-list.component.html",
    styleUrls: ["./project-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    EsaMaterialButtonComponent,
    ClearGridFiltersButtonComponent,
    CsvDownloadButtonComponent,
    AgGridAngular, 
    MatIconModule,
    MapComponent,
    MapMarkerComponent,
    MatExpansionModule
],
})
export class ProjectListComponent implements OnInit {
    @ViewChild("ProjectsGrid") projectsGrid: AgGridAngular;
    @ViewChildren('mapMarker') mapMarkers:  QueryList<MapMarkerComponent>;

    public currentUser: UserDto;
    public projects: ProjectSummaryDto[];
    public projectsWithGeoData: ProjectSummaryDto[];
    public filteredProjectsWithGeoData: ProjectSummaryDto[];

    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    pinnedBottomRowData;

    user: Subscription;
    getProjectRequest: Subscription;

    constructor(
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {
        this.columnDefs = [
            {
                headerName: "D Number",
                headerTooltip: "D Number",
                valueGetter: function (params: any) {
                    return params.node.rowPinned
                        ? "Total: " + params.data.ProjectTotal
                        : {
                            LinkValue: params.data.ProjectID,
                            LinkDisplay: params.data.DNumber,
                          };
                },
                cellRendererSelector: (params) => {
                    return params.node.rowPinned
                        ? {
                            component: CustomPinnedRowRendererComponent,
                            params:  { filter: true },
                          }
                        : {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "/projects/" },
                          };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.DNumber;
                },
                comparator: function (
                    linkA,
                    linkB,
                    nodeA,
                    nodeB,
                    isDescending
                ) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 2,
                sort: "asc",
                tooltipField: "DNumber",
            },
            {
                headerName: "Project Name",
                field: "Name",
                flex: 2,
                tooltipField: "Name",
            },
            {
                headerName: "Client Name",
                field: "ClientName",
                flex: 2,
                tooltipField: "ClientName",
            },
            {
                headerName: "Project Manager",
                field: "ProjectManager",
                flex: 2,
                tooltipField: "ProjectManager",
            },
            {
                headerName: "Status",
                field: "Status",
                flex: 1,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Status",
                },
                tooltipField: "Status",
            },
            {
                headerName: "Location",
                field: "Location",
                flex: 2,
                tooltipField: "Location",
            },
            {
                headerName: "Map",
                field: "HasGeoSpatialData",
                cellRenderer: (params) => {
                    return params.node.rowPinned ? "" : params.value ? "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_on</mat-icon>" : "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_off</mat-icon>";
                },
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef(
                "Start Date",
                "StartDate",
                "M/dd/YYYY"
            ),
            this.dateColumnCreator.createDateColumnDef(
                "End Date",
                "EndDate",
                "M/dd/YYYY"
            ),
        ];

        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: false
          };
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.getProjectRequest.unsubscribe();
    }

    navigateToProjectCreatePage() {
        this.router.navigate(["/projects/create"]);
    }

    onProjectsGridReady(gridEvent) {
        this.projectsGrid.api.showLoadingOverlay();

        this.getProjectRequest = this.projectService
            .projectsGet()
            .subscribe((results) => {
                this.rowData = results;
                this.pinnedBottomRowData = [
                    {
                        ProjectTotal: this.rowData.length,
                    },
                ];

                this.projectsGrid.api.hideOverlay();
                this.projects = results;
                this.projectsWithGeoData = results.filter((p) => p.HasGeoSpatialData);
                this.filteredProjectsWithGeoData = this.projectsWithGeoData;
                this.cdr.markForCheck();
            });
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                ProjectTotal: gridEvent.api.getDisplayedRowCount(),
            },
        ]);
        this.filteredProjectsWithGeoData = gridEvent.api.rowModel.rowsToDisplay.map((r) => r.data).filter((p) => p.HasGeoSpatialData)
    }

    onSelectionChanged($event: SelectionChangedEvent<any,any>) {
        const selectedRows = $event.api.getSelectedRows();
        const selectedRow = selectedRows[0];
        // swap the related map marker so it looks highlighted
        this.mapMarkers.forEach(marker => {
            if (marker.latitude === selectedRow.Latitude && marker.longitude === selectedRow.Longitude) {
                marker.marker._icon.classList.add('highlight');
            } else {
                marker.marker._icon.classList.remove('highlight');
            }
        });
        if (selectedRow.HasGeoSpatialData) {
            this.map.flyTo(new L.LatLng(selectedRow.Latitude, selectedRow.Longitude), 12);
        }
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.ProjectRights,
            RightsEnum.Create
        );
    }

    public map : L.Map;
    public layerControl: L.Control.Layers;
  
    mapInit(mapInitEvent: MapInitEvent) {
      this.map = mapInitEvent.map;
      this.layerControl = mapInitEvent.layerControl;
      this.cdr.markForCheck();
    }

    onMarkerClick($event: Event, obj: any) {
        this.goToSelected(obj.ProjectID);
    }
    
    goToSelected(projectID) {
        this.projectsGrid.api.forEachNode((node) => {
            if (node.data.ProjectID === projectID) {
                node.setSelected(true, true);
                this.projectsGrid.api.ensureNodeVisible(node, 'top');
                this.projectsGrid.api.setFocusedCell(node.rowIndex, "DNumber", 'top');
                
                // this allows the grid rows to expand and then scrolls to the row again.

            }
        });
    }
}
