import { Component, Input } from "@angular/core";


@Component({
    selector: "header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.scss"],
    standalone: true,
    imports: [],
})
export class HeaderNavComponent {
    @Input() environment: string;
    constructor() {}
}
