<h2 mat-dialog-title>{{ data.header }}</h2>
<mat-dialog-content>
    {{ data.text }}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <esa-material-button
        mat-dialog-close
        label="Cancel"
        icon="cancel"
        color="clear"
        style="margin-right: 1rem"
    ></esa-material-button>
    <esa-material-button
        [mat-dialog-close]="true"
        label="Okay"
        icon="done"
        color="primary"
    ></esa-material-button>
</mat-dialog-actions>
