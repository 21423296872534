<div class="site">
  <div class="header-wrapper">
    @if (qualification$ | async; as q) {
      <div class="qualification-nav">
        @if (!hasReadOnly()) {
          <div matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
            <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="profile" class="primary-button" [disabled]="editMode">
              <mat-icon>expand_more</mat-icon>&nbsp;Options
            </button>
            <mat-menu #profile="matMenu" [overlapTrigger]="false">
              @if (canDelete()) {
                <button mat-menu-item (click)="delete()">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              }
              @if (canAddToProfile()) {
                <button mat-menu-item (click)="addToProfile()">
                  <mat-icon>add</mat-icon>
                  <span>Add To Profile</span>
                </button>
              } 
            </mat-menu>
          </div>
        }
      </div>
      <div class="qualification-title">
        <h3>{{q.Name}}</h3>
      </div>
    }
  </div>

  <div class="dashboard">
    <aside class="sidebar">
      <ul class="sidebar-nav">
        @for (item of qualificationSideNav; track item) {
          <li class="sidebar-item">
            <a [routerLink]="item.Link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidebar-link">{{ item.Name }}</a>
          </li>
        }
      </ul>
    </aside>
    <div class="main">
      <router-outlet #manageOutlet="outlet"></router-outlet>
    </div>
  </div>
</div>
