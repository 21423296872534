<header-nav [environment]="environment"></header-nav>

<button class="sidebar-toggle" mat-mini-fab aria-label="Toggle the sidebar" (click)="toggle()" [class.opened]="sidenav.opened">
  <mat-icon>{{sidenav.opened ? "menu_open" : "menu" }}</mat-icon>
</button>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened>
    <talentbridge-side-nav
      [currentUser]="currentUser"
      (loginClicked)="login()"
      (logOutClicked)="logOut()"
      [userBeingImpersonated]="userBeingImpersonated"
      [userCanManageDashboard]="userCanManageDashboard"
      [userCanViewProjects]="userCanViewProjects"
      [userCanViewClients]="userCanViewClients"
      [userCanViewEmployees]="userCanViewEmployees"
      [userCanViewQualifications]="userCanViewQualifications">
    </talentbridge-side-nav>
  </mat-sidenav>

  <mat-sidenav-content>
    <main>
      <div class="site-wrapper">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        @if (!isIframe) {
          <router-outlet></router-outlet>
        }
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>