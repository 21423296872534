<form class="form grid-12" #employeeForm="ngForm">
  <div class="g-col-12">
    <esa-material-ff
      name="FirstName"
      [editMode]="false"
      label="First Name"
      type="text"
      [(ngModel)]="employee.FirstName">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="LastName"
      [editMode]="false"
      label="Last Name"
      type="text"
      [(ngModel)]="employee.LastName">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="JobTitle"
      [editMode]="false"
      label="Job Title"
      type="text"
      [(ngModel)]="employee.JobTitle">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <div class="kv-pair">
      <esa-label label="Manager"></esa-label>
      <div class="value-display ff-display">
        @if (employee.Manager) {
          <a [routerLink]="['/employees', employee.Manager.UserID]">{{ employee.Manager.FullName }}</a>
        } @else {
          -
        }
      </div>
    </div>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="Department"
      [editMode]="false"
      label="Department"
      type="text"
      [(ngModel)]="employee.Department">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="Email"
      [editMode]="false"
      label="Email"
      type="text"
      [(ngModel)]="employee.Email">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="MobilePhone"
      [editMode]="false"
      label="Mobile Phone"
      type="text"
      [(ngModel)]="employee.MobilePhone">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="BusinessPhone"
      [editMode]="false"
      label="Business Phone"
      type="text"
      [(ngModel)]="employee.BusinessPhone">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="OfficeLocation"
      [editMode]="false"
      label="Office Location"
      type="text"
      [(ngModel)]="employee.OfficeLocation">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="ZipCode"
      [editMode]="editMode"
      [required]="false"
      label="Zip Code"
      type="text"
      [(ngModel)]="employeeUpsertDto.ZipCode">
    </esa-material-ff>
  </div>
  <div class="g-col-12">
    <esa-material-ff
      name="Bio"
      [editMode]="editMode"
      [required]="false"
      label="Bio"
      type="textarea"
      [(ngModel)]="employeeUpsertDto.Bio">
    </esa-material-ff>
  </div>

  @if (editMode) {
    <div class="g-col-12 actions-bar" style="margin-top: 1rem">
      <esa-material-button
        style="margin-right: 1rem"
        type="primary"
        label="Save"
        icon="save"
        [tooltip]="!employeeForm.form.valid ? 'You are missing required fields.' : ''"
        (click)="saveForm(form)"
        [disabled]="!employeeForm.form.valid">
      </esa-material-button>
      <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
    </div>
  }
</form>
