<div class="detail-view">
  <div class="actions-bar">
    @if (canEdit()) {
      <esa-material-button type="clear" icon="edit" (click)="enableEditMode()"> </esa-material-button>
    }
  </div>

  @if (project$ | async; as p) {
    <talentbridge-project-form
      #updateProjectForm
      [projectID]="p.ProjectID"
      [project]="p"
      [editMode]="editMode"
      (formSubmitted)="save($event)"
      (cancelEditModeChange)="cancelEdit()"></talentbridge-project-form>
    <h2>Map</h2>
    <map (onMapLoad)="mapInit($event)">
      @if (map) {
        @if (p.Latitude && p.Longitude) {
          <map-marker [latitude]="p.Latitude" [longitude]="p.Longitude" [label]="p.Name" [map]="map" [zoomTo]="true"></map-marker>
        } @else {
          <div class="map-no-location">
            <p><mat-icon>location_off</mat-icon>No location data available.</p>
          </div>
        }
      }
    </map>
  }
</div>
