import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { ClientFormComponent } from '../../shared/client-form/client-form.component';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'talentbridge-client-detail-summary',
    templateUrl: './client-detail-summary.component.html',
    styleUrls: ['./client-detail-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    ClientFormComponent,
    AsyncPipe
],
})
export class ClientDetailSummaryComponent implements OnInit {
  @ViewChild("updateClientForm", { read: ClientFormComponent }) form: ClientFormComponent;

    public client$: Observable<any>;
    public currentUser: UserDto;
    public editMode = false;

    constructor(
        private currentClientService: CurrentClientService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.client$ = this.currentClientService.getCurrentClient().pipe(
            map((data) => {
                this.cdr.markForCheck();
                return data;
            })
        );
    }
}
