import {
    Component,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    ViewChild,
} from "@angular/core";
import { SubServiceService } from "src/app/shared/generated/api/sub-service.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { ColDef } from "ag-grid-community";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { AgGridAngular } from "ag-grid-angular";
import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { ClearGridFiltersButtonComponent } from "src/app/shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { CsvDownloadButtonComponent } from "src/app/shared/components/csv-download-button/csv-download-button.component";


@Component({
    selector: "talentbridge-sub-service-list",
    templateUrl: "./sub-service-list.component.html",
    styleUrls: ["./sub-service-list.component.scss"],
    standalone: true,
    imports: [
    AgGridAngular,
    EsaMaterialButtonComponent,
    ClearGridFiltersButtonComponent,
    CsvDownloadButtonComponent
]
})
export class SubServiceListComponent implements OnInit, OnDestroy {
    @ViewChild("subServicesGrid") subServicesGrid: AgGridAngular;

    private currentUser: UserDto;

    public canCreate: boolean;

    public rowData = [];
    columnDefs: ColDef[];
    public gridComponents: any;
    public defaultColDef: any;
    pinnedBottomRowData;

    public isPerformingAction: boolean = false;

    userSubscription: Subscription;
    subServiceSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private subServiceService: SubServiceService,
        private router: Router,
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
          };
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;

                this.canCreate = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.QualificationRights, RightsEnum.Create);

                this.cdr.markForCheck();

                this.createColumnDefs();
            });
    }

    createColumnDefs() {
        this.columnDefs = [
            {
                headerName: "Service Line",
                field: "ServiceCategory.ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                            field: "ServiceCategory.ServiceLine.Name",
                },
                tooltipField: "ServiceCategory.ServiceLine.Name",
            },
            {
                headerName: "Service Category",
                field: "ServiceCategory.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ServiceCategory.Name",
                },
                tooltipField: "ServiceCategory.Name",
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return params.node.rowPinned
                    ? "Total: " + params.data.SubServiceTotal
                    : {
                        LinkValue: params.data.SubServiceID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return params.node.rowPinned
                        ? {
                            component: CustomPinnedRowRendererComponent,
                            params:  { filter: true },
                          }
                        : {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "./" },
                          };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA: any, linkB: any) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 3,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
        ];
    }

    onSubServicesGridReady(gridEvent) {
        this.subServicesGrid?.api.showLoadingOverlay();
        this.updateGridData();
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                SubServiceTotal: gridEvent.api.getDisplayedRowCount(),
            },
        ]);
    }

    navigateToSubServiceCreatePage() {
        this.router.navigateByUrl("sub-services/create");
    }

    public updateGridData(): void {
        this.subServiceSubscription = this.subServiceService
            .subServicesGet()
            .subscribe((subServices) => {
                this.rowData = subServices.map((sub) => {
                    sub["$NameAndDescription"] = sub.Description ? `${sub.Name}: ${sub.Description}` : sub.Name;
                    return sub;
                });
                this.pinnedBottomRowData = [
                    {
                        SubServiceTotal: this.rowData.length,
                    }
                ]
                this.subServicesGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.subServiceSubscription?.unsubscribe();
    }
}
