import { ApplicationRef, ComponentRef, Directive, ElementRef, HostListener, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { createPopper, Instance } from '@popperjs/core';
import { AppComponent } from 'src/app/app.component';
import { PopperComponent } from '../components/popper/popper.component';

@Directive({
    selector: "[popper]",
    standalone: true,
})
export class PopperDirective {
    @Input() popper: TemplateRef<any>;
    @Input() popperTitle: TemplateRef<any> | string;
    @Input() popperOptions?: any = {
        placement: "top",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: ({ placement, reference, popper }) => {
                        return [0, 5];
                    },
                },
            },
        ],
    };
    @HostListener("click", ["$event"]) onClick(event) {
        event.stopPropagation();
        this.popperShown = !this.popperShown;
        this.toggleDisplay();
    }

    private popperInstance: Instance;
    public popperShown: boolean = false;
    private popperComponentRef: ComponentRef<PopperComponent>;

    constructor(
        private readonly el: ElementRef,
        private applicationRef: ApplicationRef,
        private renderer: Renderer2
    ) {
        this.listenForClicksOut();
    }

    listenForClicksOut(): void {
        this.renderer.listen("window", "click", (e: Event) => {
            if (
                e.target !== this.el.nativeElement &&
                !this.el.nativeElement.contains(e.target) &&
                e.target !== this.popperComponentRef.location.nativeElement &&
                !this.popperComponentRef.location.nativeElement.contains(
                    e.target
                )
            ) {
                this.popperShown = false;
                this.toggleDisplay();
            } else {
                // update the popper instance since click events elsewhere in the popper might have changed the layout
                this.popperInstance.update();
            }
        });
    }

    ngOnInit(): void {
        const viewRef = (
            this.applicationRef.components[0].instance as AppComponent
        ).viewRef;
        this.popperComponentRef = this.createPopperComponentAtViewRef(viewRef);
        this.popperInstance = createPopper(
            this.el.nativeElement,
            this.popperComponentRef.location.nativeElement,
            this.popperOptions
        );
        this.toggleDisplay();
    }

    createPopperComponentAtViewRef(viewRef: ViewContainerRef): ComponentRef<PopperComponent> {
        let component = viewRef.createComponent(PopperComponent)
        component.instance.context = this.popper;
        component.instance.title = this.popperTitle;
        component.location.nativeElement.setAttribute("data-popper", "");
        component.changeDetectorRef.detectChanges();
        return component;
    }

    ngOnDestroy(): void {
        this.popperInstance.destroy();
        this.popperComponentRef.destroy();
    }

    toggleDisplay(): void {
        if (this.popperShown) {
            this.popperComponentRef.location.nativeElement.setAttribute(
                "data-show",
                ""
            );
        } else {
            this.popperComponentRef.location.nativeElement.removeAttribute(
                "data-show"
            );
        }
        this.popperInstance.update();
    }
}
