import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'popper',
  templateUrl: './popper.component.html',
  styleUrls: ['./popper.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet]
})
export class PopperComponent implements OnInit {

  @Input() context: TemplateRef<any>; @Input() set title(value: TemplateRef<any> | string) {
    if (value instanceof TemplateRef) {
      this.titleIsTemplate = true;
      this.titleTemplate = value;
    } else {
      this.titleIsTemplate = false;
      this.titleString = value;
    }
  }

  public titleString: string;
  public titleIsTemplate: boolean;
  public titleTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {

  }

}
