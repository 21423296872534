import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, input } from '@angular/core';
import {
  Map,
} from 'leaflet';
import * as L from 'leaflet';
import { icon, Marker } from 'leaflet';

// NOTE: This sets up icon defaults.
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
Marker.prototype.options.icon = iconDefault;


@Component({
  selector: 'map-marker',
  standalone: true,
  imports: [],
  template: ''
})
export class MapMarkerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() map: Map;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() label: string;
  @Input() zoomTo: boolean = false;

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  
  marker: Marker;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.marker) {
      this.marker.setLatLng([this.latitude, this.longitude], { title: this.label });
      this.marker._icon.title = this.label;
      this.marker.update();
    }
  }

  ngOnDestroy(): void {
    this.marker.remove();
  }

  ngOnInit(): void {
    if (this.latitude === null || this.longitude === null || this.latitude === undefined || this.longitude === undefined) {
      return;
    }
    this.marker = L.marker([this.latitude, this.longitude], { title: this.label });

    this.marker.on('click', (e) => {
      this.onClick.emit(e);
    });

    this.marker.addTo(this.map);
    if (this.zoomTo) {
      this.map.setView([this.latitude, this.longitude], 13);
    }
  }

}
