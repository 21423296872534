<div class="site">
  <div class="header-wrapper">
    @if (client$ | async; as cli) {
      <div class="client-nav">
      </div>
      <div class="client-title">
        <h3>{{cli.Name}}</h3>
      </div>
    }
  </div>

  <div class="dashboard">
    <aside class="sidebar">
      <ul class="sidebar-nav">
        @for (item of clientSideNav; track item) {
          <li class="sidebar-item">
            <a [routerLink]="item.Link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidebar-link">{{ item.Name }}</a>
          </li>
        }
      </ul>
    </aside>
    <div class="main">
      <router-outlet #manageOutlet="outlet"></router-outlet>
    </div>
  </div>
</div>
