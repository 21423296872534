<div class="header-wrapper">
    <div class="employee-title">
        <h3>Qualifications</h3>
    </div>
</div>

<div class="detail-view">
    
    @if (employeeQualificationDtos && employee) {
        <div class="actions-bar">
            @if (canEdit(employee)) {
                <esa-material-button type="clear" icon="edit" (click)="enableEditMode()">
                </esa-material-button>
            }
        </div>
        <mat-chip-set aria-label="Qualifications">
            @for (emp of employeeQualificationDtos; track emp) {
                <mat-chip (removed)="remove(emp)">
                    <a [routerLink]="['/qualifications', emp.Qualification.QualificationID]">{{emp.Qualification.Name}}</a>
                    @if (emp.ExperienceLevel) {
                        @for (_ of [].constructor(emp.ExperienceLevel.ExperienceLevelID); track _) {
                            <mat-icon [title]="emp.ExperienceLevel.DisplayName">star_rate</mat-icon>
                        }
                    }
                    @if (editMode) {
                        <button matChipRemove [attr.aria-label]="'Remove ' + emp.Qualification.Name">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    }
                </mat-chip>
            }
            
        </mat-chip-set>
        @if (editMode) {
            <div class="new-chips-bar" style="margin-top: 1rem">
                <mat-form-field >
                    <mat-label>Qualification</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput
                        type="text"
                        name="currentQualification" 
                        placeholder="New Qualification..." 
                        [(ngModel)]="currentQualification" 
                        [matAutocomplete]="auto" />
                        
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="getName.bind(this)">
                       
                        @for (serviceLine of filteredQualifications(); track serviceLine) {
                            <mat-optgroup [label]="serviceLine.ServiceLine">
                                @for (serviceCategory of serviceLine.ServiceCategories; track serviceCategory) {
                                    <mat-optgroup [label]="serviceCategory.ServiceCategory">
                                        @for (subService of serviceCategory.SubServices; track subService) {
                                            <mat-optgroup [label]="subService.SubService">
                                                @for (qual of subService.Qualifications; track qual) {
                                                    <mat-option [value]="qual">{{qual.Name}}</mat-option>
                                                }
                                            </mat-optgroup>
                                        }
                                    </mat-optgroup>
                                }
                            </mat-optgroup>
                        }
                    </mat-autocomplete>                        
                </mat-form-field>

                @if (this.selectedQualification?.QualificationType.RequiresExperienceLevel) {
                    <mat-form-field class="exp-level" >
                        <mat-label>Experience Level</mat-label>
                        <mat-select [(value)]="selectedExperienceLevel">
                            @for (expLevel of experienceLevels; track $index) {
                                <mat-option [value]="expLevel">
                                    {{expLevel.DisplayName}}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
                <esa-material-button class="add-chips" title="Add Qualification" label="Add" icon="add" (click)="add()">Add
                </esa-material-button>
            </div>
                    
            <div class="g-col-12 actions-bar" style="margin-top: 1rem">
                <esa-material-button
                    style="margin-right: 1rem"
                    type="primary"
                    label="Save"
                    icon="save"
                    (click)="save()">
                </esa-material-button>

                <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
            </div>
        }
    }
</div>