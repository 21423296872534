<div class="detail-view">
    
  <div class="actions-bar flex-between">
    <h1 class="page-title">Configuration</h1>
    @if (canEdit()) {
      <esa-material-button type="clear" icon="edit" (click)="enableEditMode()"> </esa-material-button>
    }
  </div>
  @if (configuration$ | async; as config) { 
    <talentbridge-configuration-form
      #configurationForm
      [editMode]="editMode"
      [adminConfiguration]="config"
      (cancelEditModeChange)="cancelEdit()">
    </talentbridge-configuration-form>
  }
</div>