import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'talentbridge-link-renderer-with-image',
    templateUrl: './link-renderer-with-image.component.html',
    styleUrls: ['./link-renderer-with-image.component.scss'],
    standalone: true,
    imports: [RouterLink]
})

export class LinkRendererWithImageComponent implements AgRendererComponent {
  params: any;    

  agInit(params: any): void {
    if(params.value === null)
    {
      params = { value: { LinkDisplay: "", LinkValue: "", ChildRoute: "", QueryParams: null, ImageSrc: "" }, inRouterLink: "" };
    }
    else
    {
      this.params = params;
    }
  }

  refresh(params: any): boolean {
      return false;
  }    
}