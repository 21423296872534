<div class="site">
  <div class="header-wrapper">
    @if (subService$ | async; as ss) {
      <div class="sub-service-nav">
        @if (!hasReadOnly()) {
          <div matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
            <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="profile" class="primary-button" [disabled]="editMode">
              <mat-icon>expand_more</mat-icon>&nbsp;Options
            </button>
            <mat-menu #profile="matMenu" [overlapTrigger]="false">
              @if (canDelete()) {
                <div
                  matTooltip="This Sub-Service is associated with Qualifications; please delete the Qualifications or move them to a different Sub-Service before deleting."
                  [matTooltipDisabled]="!hasChildren()">
                  <button mat-menu-item (click)="delete()" [disabled]="hasChildren()">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                </div>
              }
            </mat-menu>
          </div>
        }
      </div>
      <div class="sub-service-title">
        <h3>{{ss.Name}}</h3>
      </div>
    }
  </div>

  <div class="dashboard">
    <aside class="sidebar">
      <ul class="sidebar-nav">
        @for (item of subServiceSideNav; track item) {
          <li class="sidebar-item">
            <a [routerLink]="item.Link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidebar-link">{{ item.Name }}</a>
          </li>
        }
      </ul>
    </aside>
    <div class="main">
      <router-outlet #manageOutlet="outlet"></router-outlet>
    </div>
  </div>
</div>
