import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEmployeeService } from 'src/app/services/current-employee.service';
import { EmployeeDto } from 'src/app/shared/generated/model/employee-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { EmployeeService } from 'src/app/shared/generated/api/employee.service';
import { CustomPinnedRowRendererComponent } from 'src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { CsvDownloadButtonComponent } from 'src/app/shared/components/csv-download-button/csv-download-button.component';
import { ClearGridFiltersButtonComponent } from 'src/app/shared/components/clear-grid-filters-button/clear-grid-filters-button.component';

@Component({
    selector: 'talentbridge-employee-direct-reports-list',
    templateUrl: './employee-direct-reports-list.component.html',
    styleUrls: ['./employee-direct-reports-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        ClearGridFiltersButtonComponent,
        CsvDownloadButtonComponent,
        AgGridAngular,
    ]
})
export class EmployeeDirectReportsListComponent implements OnInit, OnDestroy {
    @ViewChild("employeeDirectReportsGrid") employeeDirectReportsGrid: AgGridAngular;

    public employee: EmployeeDto;
    public currentUser: UserDto;
    public rowData: any[];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    public employee$: Observable<any>;

    pinnedBottomRowData;
    currentUserSubscription: Subscription;
    employeeDirectReportsSubscription: Subscription;

    constructor(
        private employeeService: EmployeeService,
        private currentEmployeeService: CurrentEmployeeService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
        };
    }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((user) => {
            this.currentUser = user;

            this.currentEmployeeService.currentEmployee$.subscribe((employee) => {
                this.employee = employee;
                this.columnDefs = [
                    {
                        headerName: "Employee Name",
                        headerTooltip: "Employee Name",
                        valueGetter: function (params: any) {
                            return params.node.rowPinned
                                ? "Total: " + params.data.DirectReportsTotal
                                : {
                                    LinkValue: params.data.UserID,
                                    LinkDisplay: params.data.FullName,
                                };
                        },
                        cellRendererSelector: (params) => {
                            return params.node.rowPinned
                                ? {
                                    component: CustomPinnedRowRendererComponent,
                                    params:  { filter: true },
                                }
                                : {
                                    component: LinkRendererComponent,
                                    params: { inRouterLink: "/employees/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.node.rowPinned ? null : params.data.FullName;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "FullName",
                    },
                    {
                        headerName: "Job Title",
                        field: "JobTitle",
                        flex: 2,
                        tooltipField: "JobTitle",
                        wrapText: true,
                    },
                    {
                        headerName: "Department",
                        field: "Department",
                        flex: 2,
                        tooltipField: "Department",
                        wrapText: true,
                    },
                    {
                        headerName: "Email",
                        field: "Email",
                        flex: 2,
                        tooltipField: "Email",
                        wrapText: true,
                    },
                    {
                        headerName: "Phone",
                        field: "$Phone",
                        flex: 1,
                        tooltipField: "$Phone",
                        wrapText: true,
                    },
                    {
                        headerName: "Office Location",
                        field: "OfficeLocation",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "OfficeLocation",
                        },
                        tooltipField: "OfficeLocation",
                        wrapText: true,
                    },
                    {
                        headerName: "Zip Code",
                        field: "ZipCode",
                        flex: 1,
                        tooltipField: "ZipCode",
                        wrapText: true,
                    },
                ];
                
                this.refreshData();
            });
        });
    }

    onEmployeeDirectReportsGridReady(event) {
        this.employeeDirectReportsGrid.api.hideOverlay();
    }
    
    refreshData() {
        if (this.employee) {
            this.employeeDirectReportsSubscription = this.employeeService.employeesUserIDDirectReportsGet(this.employee.UserID).subscribe((result) => {
                this.rowData = result;

                this.pinnedBottomRowData = [
                    {
                        DirectReportsTotal: this.rowData.length,
                    },
                ];
    
                this.cdr.markForCheck();
            });
        }
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.employeeDirectReportsSubscription?.unsubscribe();
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                DirectReportsTotal: gridEvent.api.getDisplayedRowCount(),
            },
        ]);
    }
}
