import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { MatIconModule } from "@angular/material/icon";
import * as L from 'leaflet';
import { MapComponent, MapInitEvent as MapInitEvent } from 'src/app/shared/components/map/map.component';
import { MapMarkerComponent } from 'src/app/shared/components/map/map-marker/map-marker.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { ClearGridFiltersButtonComponent } from "src/app/shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { CsvDownloadButtonComponent } from "src/app/shared/components/csv-download-button/csv-download-button.component";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { QualificationEmployeeDto } from "src/app/shared/generated/model/qualification-employee-dto";
import { CurrentQualificationService } from "src/app/services/current-qualification.service";
import { QualificationDto } from "src/app/shared/generated/model/qualification-dto";
import { environment } from "src/environments/environment";
import { LinkRendererWithImageComponent } from "src/app/shared/components/ag-grid/link-renderer-with-image/link-renderer-with-image.component";

@Component({
    selector: "talentbridge-qualification-employee-list",
    templateUrl: "./qualification-employee-list.component.html",
    styleUrls: ["./qualification-employee-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        EsaMaterialButtonComponent,
        ClearGridFiltersButtonComponent,
        CsvDownloadButtonComponent,
        AgGridAngular,
        MatIconModule,
        MapComponent,
        MapMarkerComponent,
        MatExpansionModule
    ],
})
export class QualificationEmployeeListComponent implements OnInit {
    @ViewChild("EmployeeGrid") employeeGrid: AgGridAngular;
    @ViewChildren('mapMarker') mapMarkers: QueryList<MapMarkerComponent>;

    public qualification: QualificationDto;
    public currentUser: UserDto;
    public employees: QualificationEmployeeDto[];
    public zipsWithGeoData: Set<{ ZipCode: string, Latitude: number, Longitude: number }>;
    public filteredZipsWithGeoData: Set<{ ZipCode: string, Latitude: number, Longitude: number }>;

    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    pinnedBottomRowData;

    user: Subscription;
    getQualificationEmployeeRequest: Subscription;

    constructor(
        private qualificationService: QualificationService,
        private authenticationService: AuthenticationService,
        private currentQualificationService: CurrentQualificationService,
        private cdr: ChangeDetectorRef
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
        };
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;

                this.columnDefs = [
                    {
                        headerName: "Employee Name",
                        headerTooltip: "Employee Name",
                        valueGetter: function (params: any) {
                            return params.node.rowPinned
                                ? "Total: " + params.data.EmployeeTotal
                                : {
                                    LinkValue: params.data.UserID,
                                    LinkDisplay: params.data.FullName,
                                    ImageSrc: `${environment.mainAppApiUrl}/users/${params.data.UserID}/photoThumbnail?uid=${result.UserGuid}&secure=${result.FileAccessToken}`
                                };
                        },
                        cellRendererSelector: (params) => {
                            return params.node.rowPinned
                                ? {
                                    component: CustomPinnedRowRendererComponent,
                                    params: { filter: true },
                                }
                                : {
                                    component: LinkRendererWithImageComponent,
                                    params: { inRouterLink: "/employees/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.node.rowPinned ? null : params.data.FullName;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
        
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "FullName",
                    },
                    {
                        headerName: "Job Title",
                        field: "JobTitle",
                        flex: 2,
                        tooltipField: "JobTitle",
                        wrapText: true,
                    },
                    {
                        headerName: "Department",
                        field: "Department",
                        flex: 2,
                        tooltipField: "Department",
                        wrapText: true,
                    },
                    {
                        headerName: "Email",
                        field: "Email",
                        flex: 2,
                        tooltipField: "Email",
                        wrapText: true,
                    },
                    {
                        headerName: "Experience Level",
                        field: "ExperienceLevel.Name",
                        flex: 2,
                        filter: CustomDropdownFilterComponent, 
                        filterParams: {
                            field: "ExperienceLevel.Name",
                        },
                        tooltipField: "ExperienceLevel.Name",
                        wrapText: true,
                    },
                    {
                        headerName: "Office Location",
                        field: "OfficeLocation",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "OfficeLocation",
                        },
                        tooltipField: "OfficeLocation",
                        wrapText: true,
                    },
                    {
                        headerName: "Zip Code",
                        field: "ZipCode",
                        flex: 1,
                        tooltipField: "ZipCode",
                        wrapText: true,
                    },
                    {
                        headerName: "Map",
                        field: "HasGeoSpatialData",
                        cellRenderer: (params) => {
                            return params.node.rowPinned ? "" : params.value ? "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_on</mat-icon>" : "<mat-icon class='mat-icon material-icons mat-icon-no-color'>location_off</mat-icon>";
                        },
                        flex: 1,
                    },
                ];
        
                this.currentQualificationService.getCurrentQualification().subscribe((qualification) => {
                    this.qualification = qualification;
                    if (qualification !== null) {
                        this.getQualificationEmployeeRequest = this.qualificationService
                            .qualificationsQualificationIDEmployeesGet(this.qualification.QualificationID)
                            .subscribe((results) => {
                                this.rowData = results;
                                this.pinnedBottomRowData = [
                                    {
                                        EmployeeTotal: this.rowData.length,
                                    },
                                ];

                                this.employeeGrid.api.hideOverlay();
                                this.employees = results;
                                this.zipsWithGeoData = new Set(results.filter((p) => p.HasGeoSpatialData).map((p) => 
                                    { 
                                        return { ZipCode: p.ZipCode, Latitude: p.ZipCodeLatitude, Longitude: p.ZipCodeLongitude } 
                                    }));
                                this.filteredZipsWithGeoData = this.zipsWithGeoData;
                                this.cdr.markForCheck();
                            });
                        this.cdr.markForCheck();
                    }
                });
            });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.getQualificationEmployeeRequest.unsubscribe();
    }

    onGridReady(gridEvent) {
        this.employeeGrid.api.showLoadingOverlay();

    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                EmployeeTotal: gridEvent.api.getDisplayedRowCount(),
            },
        ]);
        this.filteredZipsWithGeoData = new Set(gridEvent.api.rowModel.rowsToDisplay.map((r) => r.data).filter((p) => p.HasGeoSpatialData).map((p) => 
            { 
                return { ZipCode: p.ZipCode, Latitude: p.ZipCodeLatitude, Longitude: p.ZipCodeLongitude } 
            }));
    }

    onSelectionChanged($event: SelectionChangedEvent<any, any>) {
        const selectedRows = $event.api.getSelectedRows();
        const selectedRow = selectedRows[0];
        // swap the related map marker so it looks highlighted
        this.mapMarkers.forEach(marker => {
            if (marker.latitude === selectedRow.ZipCodeLatitude && marker.longitude === selectedRow.ZipCodeLongitude) {
                marker.marker._icon.classList.add('highlight');
            } else {
                marker.marker._icon.classList.remove('highlight');
            }
        });
    }

    public map: L.Map;
    public layerControl: L.Control.Layers;

    mapInit(mapInitEvent: MapInitEvent) {
        this.map = mapInitEvent.map;
        this.layerControl = mapInitEvent.layerControl;
        this.cdr.markForCheck();
    }
}
