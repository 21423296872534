import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatOption, MatSelect } from "@angular/material/select";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { ExperienceLevelService } from "src/app/shared/generated/api/experience-level.service";
import { EmployeeQualificationUpsertDto } from "src/app/shared/generated/model/employee-qualification-upsert-dto";
import { ExperienceLevelDto } from "src/app/shared/generated/model/experience-level-dto";

@Component({
    selector: "talentbridge-qualification-add-dialog",
    templateUrl: "qualification-add-dialog.component.html",
    styleUrls: ["./qualification-add-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        EsaMaterialButtonComponent,
        MatFormFieldModule,
        MatLabel,
        MatSelect,
        MatOption,
        MatInputModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationAddDialog {
    experienceLevels:ExperienceLevelDto[];
    selectedExperienceLevel: ExperienceLevelDto;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<QualificationAddDialog>,
        private experienceLevelService: ExperienceLevelService,
        private cdr: ChangeDetectorRef
    ) { 
        if (data.Qualification.QualificationType.RequiresExperienceLevel) {
            this.experienceLevelService.experienceLevelsGet().subscribe(levels => {
                this.experienceLevels = levels;
                this.cdr.detectChanges();
            });
        }

    }

    closeDialog(cancel: boolean) {
        if (cancel) {
            this.dialogRef.close(null);
            return;
        }

        var dto: EmployeeQualificationUpsertDto = {
            QualificationID: this.data.Qualification.QualificationID,
            ExperienceLevelID: this.selectedExperienceLevel?.ExperienceLevelID
        };

        this.dialogRef.close(dto);
    }
}