<h2 mat-dialog-title>{{ data.Qualification.Name }}</h2>
<mat-dialog-content>
    <p>Add {{ data.Qualification.Name }} to your profile?</p>
    @if (data.Qualification.QualificationType.RequiresExperienceLevel) {
        <mat-form-field class="exp-level" >
            <mat-label>Experience Level</mat-label>
            <mat-select [(value)]="selectedExperienceLevel">
                @for (expLevel of experienceLevels; track $index) {
                    <mat-option [value]="expLevel">
                        {{expLevel.DisplayName}}
                    </mat-option>
                } 
            </mat-select>
        </mat-form-field>
    }   


</mat-dialog-content>
<mat-dialog-actions align="end">
    <esa-material-button
        label="Cancel"
        icon="cancel"
        color="clear"
        style="margin-right: 1rem"
        (click)="closeDialog(true)"
    ></esa-material-button>
    <esa-material-button
        label="Okay"
        icon="done"
        color="primary"
        [disabled]="this.data.Qualification.QualificationType.RequiresExperienceLevel && !this.selectedExperienceLevel"
        (click)="closeDialog(false)"
    ></esa-material-button>
</mat-dialog-actions>
