import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { CustomPinnedRowRendererComponent } from 'src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { CsvDownloadButtonComponent } from 'src/app/shared/components/csv-download-button/csv-download-button.component';
import { ClearGridFiltersButtonComponent } from 'src/app/shared/components/clear-grid-filters-button/clear-grid-filters-button.component';
import { ClientService } from 'src/app/shared/generated/api/client.service';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { ClientDto } from 'src/app/shared/generated/model/client-dto';
import { LinkRendererWithImageComponent } from 'src/app/shared/components/ag-grid/link-renderer-with-image/link-renderer-with-image.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'talentbridge-client-employee-list',
    templateUrl: './client-employee-list.component.html',
    styleUrls: ['./client-employee-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        ClearGridFiltersButtonComponent,
        CsvDownloadButtonComponent,
        AgGridAngular,
    ]
})
export class ClientEmployeeListComponent implements OnInit, OnDestroy {
    @ViewChild("clientEmployeeGrid") clientEmployeeGrid: AgGridAngular;

    public client: ClientDto;
    public currentUser: UserDto;
    public rowData: any[];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    public client$: Observable<any>;

    pinnedBottomRowData;
    currentUserSubscription: Subscription;
    clientEmployeesSubscription: Subscription;

    constructor(
        private clientService: ClientService,
        private currentClientService: CurrentClientService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: false
        };
    }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((user) => {
            this.currentUser = user;

            this.currentClientService.currentClient$.subscribe((client) => {
                this.client = client;
                this.columnDefs = [
                    {
                        headerName: "Employee Name",
                        headerTooltip: "Employee Name",
                        valueGetter: function (params: any) {
                            return params.node.rowPinned
                                ? "Total: " + params.data.EmployeeTotal
                                : {
                                    LinkValue: params.data.UserID,
                                    LinkDisplay: params.data.FullName,
                                    ImageSrc: `${environment.mainAppApiUrl}/users/${params.data.UserID}/photoThumbnail?uid=${user.UserGuid}&secure=${user.FileAccessToken}`
                                };
                        },
                        cellRendererSelector: (params) => {
                            return params.node.rowPinned
                                ? {
                                    component: CustomPinnedRowRendererComponent,
                                    params:  { filter: true },
                                }
                                : {
                                    component: LinkRendererWithImageComponent,
                                    params: { inRouterLink: "/employees/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.node.rowPinned ? null : params.data.FullName;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "FullName",
                    },
                    {
                        headerName: "Projects",
                        field: "Projects",
                        flex: 2,
                        tooltipField: "Projects",
                    },
                    {
                        headerName: "Job Title",
                        field: "JobTitle",
                        flex: 2,
                        tooltipField: "JobTitle",
                        wrapText: true,
                    },
                    {
                        headerName: "Department",
                        field: "Department",
                        flex: 2,
                        tooltipField: "Department",
                        wrapText: true,
                    },
                    {
                        headerName: "Email",
                        field: "Email",
                        flex: 2,
                        tooltipField: "Email",
                        wrapText: true,
                    },
                    {
                        headerName: "Office Location",
                        field: "OfficeLocation",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "OfficeLocation",
                        },
                        tooltipField: "OfficeLocation",
                        wrapText: true,
                    },
                    {
                        headerName: "Zip Code",
                        field: "ZipCode",
                        flex: 1,
                        tooltipField: "ZipCode",
                        wrapText: true,
                    },
                ];
                
                this.refreshData();
            });
        });
    }

    onClientEmployeeGridReady(event) {
        this.clientEmployeeGrid.api.hideOverlay();
    }
    
    refreshData() {
        if (this.client) {
            this.clientEmployeesSubscription = this.clientService.clientsClientIDEmployeesGet(this.client.ClientID).subscribe((result) => {
                this.rowData = result;

                this.pinnedBottomRowData = [
                    {
                        EmployeeTotal: this.rowData.length,
                    },
                ];
    
                this.cdr.markForCheck();
            });
        }
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.clientEmployeesSubscription?.unsubscribe();
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                EmployeeTotal: gridEvent.api.getDisplayedRowCount(),
            },
        ]);
    }
}
