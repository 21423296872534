@if (projectUpsertDto) {
  <form class="form grid-12" #projectForm="ngForm">
    <div class="g-col-6">
      <esa-material-ff
        name="Name"
        [editMode]="editMode"
        [required]="true"
        type="text"
        [(ngModel)]="projectUpsertDto.Name"
        [customLabel]="projectNameCustomLabel">
      </esa-material-ff>
      <ng-template #projectNameCustomLabel>
        <talentbridge-custom-form-label label="Name" fieldDefinitionType="ProjectName"></talentbridge-custom-form-label>
      </ng-template>
    </div>

    <div class="g-col-6">
        <div class="kv-pair">
          <esa-label label="Client" [required]="true"></esa-label>
            @if (!editMode) {
              <div class="value-display ff-display">
              @if (project.Client) {
                <a [routerLink]="['/clients', project.Client.ClientID]">{{ project.Client.Name }}</a>
              } @else {
                -
              }           
              </div>
            }
            @else {
              <mat-form-field>
                <mat-label>Client</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput
                  type="text"
                  name="currentClient" 
                  placeholder="Client" 
                  [(ngModel)]="currentClient" 
                  [matAutocomplete]="auto" />

                @if (currentClient() !== '') {
                  <button matSuffix mat-icon-button aria-label="Clear" (click)="clearAutoComplete()">
                    <mat-icon>close</mat-icon>
                  </button>
                }
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (closed)="closed()" [displayWith]="getName">
                    @for (client of filteredClients(); track client) {
                        <mat-option [value]="client">{{client.Name}}</mat-option>
                    }
                </mat-autocomplete>                        
            </mat-form-field>
            }
        </div>
    
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="DNumber"
        [editMode]="editMode"
        [required]="true"
        label="D Number"
        type="text"
        [(ngModel)]="projectUpsertDto.DNumber">
      </esa-material-ff>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="Status"
        [editMode]="editMode"
        [required]="true"
        label="Status"
        type="text"
        [(ngModel)]="projectUpsertDto.Status">
      </esa-material-ff>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="ProjectManager"
        [editMode]="editMode"
        [required]="true"
        label="Project Manager"
        type="text"
        [(ngModel)]="projectUpsertDto.ProjectManager">
      </esa-material-ff>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="Location"
        [editMode]="editMode"
        label="Location"
        type="text"
        [(ngModel)]="projectUpsertDto.Location">
      </esa-material-ff>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="StartDate"
        [editMode]="editMode"
        type="date"
        [(ngModel)]="projectUpsertDto.StartDate"
        [customLabel]="StartDateCustomLabel">
      </esa-material-ff>
      <ng-template #StartDateCustomLabel>
        <talentbridge-custom-form-label label="Start Date" fieldDefinitionType="ProjectDate"></talentbridge-custom-form-label>
      </ng-template>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="EndDate"
        [editMode]="editMode"
        type="date"
        [(ngModel)]="projectUpsertDto.EndDate"
        [customLabel]="EndDateCustomLabel">
      </esa-material-ff>
      <ng-template #EndDateCustomLabel>
        <talentbridge-custom-form-label label="End Date" fieldDefinitionType="ProjectDate"></talentbridge-custom-form-label>
      </ng-template>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="Latitude"
        [editMode]="editMode"
        type="text"
        [(ngModel)]="projectUpsertDto.Latitude"
        [customLabel]="LatitudeCustomLabel">
      </esa-material-ff>
      <ng-template #LatitudeCustomLabel>
        <talentbridge-custom-form-label label="Latitude"></talentbridge-custom-form-label>
      </ng-template>
    </div>

    <div class="g-col-6">
      <esa-material-ff
        name="Longitude"
        [editMode]="editMode"
        type="text"
        [(ngModel)]="projectUpsertDto.Longitude"
        [customLabel]="LongitudeCustomLabel">
      </esa-material-ff>
      <ng-template #LongitudeCustomLabel>
        <talentbridge-custom-form-label label="Longitude"></talentbridge-custom-form-label>
      </ng-template>
    </div>

    <div class="g-col-12">
      <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
        <talentbridge-custom-form-label label="Description" fieldDefinitionType="ProjectDescription"></talentbridge-custom-form-label>
        @if (editMode) {
          <div class="value">
            <div class="grid-12">
              <div class="g-col-12">
                <tinymce-editor #descriptionEditor name="Description" [(content)]="projectUpsertDto.Description"></tinymce-editor>
              </div>
            </div>
          </div>
        }
        @if (!editMode) {
          <div
            [innerHTML]="projectUpsertDto.Description === null ? '-' : (projectUpsertDto.Description | bypassHtml)"
            class="value-display"
          ></div>
        }
      </div>
    </div>

    <div class="g-col-12">
      <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
        <talentbridge-custom-form-label label="Standard Description"></talentbridge-custom-form-label>
        @if (editMode) {
          <div class="value">
            <div class="grid-12">
              <div class="g-col-12">
                <tinymce-editor #standardEditor name="StandardDescription" [(content)]="projectUpsertDto.StandardDescription"></tinymce-editor>
              </div>
            </div>
          </div>
        }
        @if (!editMode) {
          <div
            [innerHTML]="projectUpsertDto.StandardDescription === null ? '-' : (projectUpsertDto.StandardDescription | bypassHtml)"
            class="value-display"
          ></div>
        }
      </div>
    </div>

    <div class="g-col-12">
      <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
        <talentbridge-custom-form-label label="Opportunity Description"></talentbridge-custom-form-label>
        @if (editMode) {
          <div class="value">
            <div class="grid-12">
              <div class="g-col-12">
                <tinymce-editor #opportunityEditor name="OpportunityDescription" [(content)]="projectUpsertDto.OpportunityDescription"></tinymce-editor>
              </div>
            </div>
          </div>
        }
        @if (!editMode) {
          <div
            [innerHTML]="projectUpsertDto.OpportunityDescription === null ? '-' : (projectUpsertDto.OpportunityDescription | bypassHtml)"
            class="value-display"
          ></div>
        }
      </div>
    </div>

    @if (editMode) {
      <div class="g-col-12 actions-bar" style="margin-top: 1rem">
        <esa-material-button
          style="margin-right: 1rem"
          type="primary"
          label="Save"
          icon="save"
          [tooltip]="!projectForm.form.valid ? 'You are missing required fields.' : ''"
          (click)="saveForm(form)"
          [disabled]="!projectForm.form.valid">
        </esa-material-button>
        <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
      </div>
    }
  </form>
}