import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { QualificationFormComponent } from '../shared/qualification-form/qualification-form.component';
import { CurrentQualificationService } from 'src/app/services/current-qualification.service';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { QualificationUpsertDto } from 'src/app/shared/generated/model/qualification-upsert-dto';


@Component({
  selector: 'talentbridge-qualification-create',
  templateUrl: './qualification-create.component.html',
  styleUrls: ['./qualification-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    QualificationFormComponent
]
})
export class QualificationCreateComponent implements OnInit, OnDestroy {        
  @ViewChild("createNewQualificationForm", { read: QualificationFormComponent }) form: QualificationFormComponent;

  public qualification: QualificationDto = new QualificationDto();
  public qualificationUpsertDto: QualificationUpsertDto;
  private qualificationResponse: any;

  editMode = true;

  constructor(
      private currentQualificationService: CurrentQualificationService,
      private router: Router,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.qualificationUpsertDto = this.currentQualificationService.createQualificationDto(this.qualification);
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  save(result): void {
    if (!!result) {
        this.qualificationResponse = result;
        this.router.navigateByUrl(`qualifications/${result.QualificationID}`);
    } else {
        this.cdr.detectChanges();
    }
  }

  cancelEdit() {
      this.router.navigateByUrl("qualifications");
  }

  canExit() {
      if (this.qualificationResponse) {
          return true;
      } else {
          return JSON.stringify(this.qualificationUpsertDto) === JSON.stringify(this.form.qualificationUpsertDto);
      }
  }
}
