import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, model, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEmployeeService } from 'src/app/services/current-employee.service';
import { EmployeeQualificationService } from 'src/app/shared/generated/api/employee-qualification.service';
import { QualificationService } from 'src/app/shared/generated/api/qualification.service';
import { EmployeeDto } from 'src/app/shared/generated/model/employee-dto';
import { EmployeeQualificationSummaryDto } from 'src/app/shared/generated/model/employee-qualification-summary-dto';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { FormsModule } from '@angular/forms';
import { EmployeeQualificationUpsertDto } from 'src/app/shared/generated/model/employee-qualification-upsert-dto';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ExperienceLevelService } from 'src/app/shared/generated/api/experience-level.service';
import { ExperienceLevelDto } from 'src/app/shared/generated/model/experience-level-dto';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'talentbridge-employee-qualifications',
    templateUrl: './employee-qualifications.component.html',
    styleUrls: ['./employee-qualifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialButtonComponent,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatLabel,
        MatSelect,
        MatInputModule,
        RouterLink
    ]
})
export class EmployeeQualificationsComponent implements OnInit, OnDestroy {

    public employee: EmployeeDto;
    public currentUser: UserDto;
    public employee$: Observable<EmployeeDto>;
    public qualificationsSubscription: Subscription;
    public experienceLevelSubscription: Subscription;
    public employeeQualifications$: Observable<Array<EmployeeQualificationSummaryDto>>;

    _hierarchyContainsText(q: QualificationDto, currentQualification: any): boolean {
        var qualName = "";
        if (currentQualification.toLowerCase) { //this is a string, sometimes it's the object
            qualName = currentQualification.toLowerCase();
        }
        else {
            return true;
        }
        
        return q.Name.toLowerCase().includes(qualName)
            || q.SubService.Name.toLowerCase().includes(qualName)
            || q.SubService.ServiceCategory.Name.toLowerCase().includes(qualName)
            || q.SubService.ServiceCategory.ServiceLine.Name.toLowerCase().includes(qualName);
    }

    // Signals used for autoComplete
    readonly currentQualification = model('');
    readonly filteredQualifications = computed(() => {
        const currentQualification = this.currentQualification();
        var filteredQuals = currentQualification
            ? this.qualifications.filter(q => (this._hierarchyContainsText(q, currentQualification)) && !this.employeeQualificationDtos.some(eq => eq.Qualification.QualificationID === q.QualificationID))
            : this.qualifications.filter(q => !this.employeeQualificationDtos.some(eq => eq.Qualification.QualificationID === q.QualificationID)).slice();

        var groupedQuals: any[] = [];

        //group into service line, service category, sub service, qualifications... flipping the hierarchy
        filteredQuals.forEach((q) => {
            var serviceLine = q.SubService.ServiceCategory.ServiceLine.Name;
            var serviceCategory = q.SubService.ServiceCategory.Name;
            var subService = q.SubService.Name;

            var sl = groupedQuals.find(g => g.ServiceLine === serviceLine);
            if (!sl) {
                sl = { ServiceLine: serviceLine, ServiceCategories: [] };
                groupedQuals.push(sl);
            }
            var sc = sl.ServiceCategories.find(g => g.ServiceCategory === serviceCategory);
            if (!sc) {
                sc = { ServiceCategory: serviceCategory, SubServices: [] };
                sl.ServiceCategories.push(sc);
            }
            var ss = sc.SubServices.find(g => g.SubService === subService);
            if (!ss) {
                ss = { SubService: subService, Qualifications: [] };
                sc.SubServices.push(ss);
            }
            ss.Qualifications.push(q);
        });

        return groupedQuals;
    });

    currentUserSubscription: Subscription;
    employeeQualificationsSubscription: Subscription;
    editMode: boolean = false;
    selectedQualification: QualificationDto;
    selectedExperienceLevel: ExperienceLevelDto;
    qualifications: QualificationDto[];
    employeeQualificationUpsertDtos: EmployeeQualificationUpsertDto[];
    employeeQualificationDtos: EmployeeQualificationSummaryDto[];
    experienceLevels: ExperienceLevelDto[];
    originalEmployeeQualificationUpsertDtos: EmployeeQualificationUpsertDto[];

    constructor(
        private currentEmployeeService: CurrentEmployeeService,
        private authenticationService: AuthenticationService,
        private employeeQualificationService: EmployeeQualificationService,
        private qualificationService: QualificationService,
        private experienceLevelService: ExperienceLevelService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((user) => {
            this.currentUser = user;
            this.currentEmployeeService.currentEmployee$.subscribe((employee) => {
                this.employee = employee;

                this.refreshData();
            });
        });
    }


    refreshData() {
        if (this.employee) {
            this.employeeQualificationsSubscription = this.employeeQualificationService.employeesUserIDQualificationsGet(this.employee.UserID).subscribe((employeeQualifications) => {
                this.employeeQualificationDtos = employeeQualifications;
                this.employeeQualificationUpsertDtos = this.createEmployeeQualificationDtos(employeeQualifications);
                //create a copy of the dtos. This is used to check if the user has made any changes
                this.originalEmployeeQualificationUpsertDtos = JSON.parse(JSON.stringify(this.employeeQualificationUpsertDtos));

                this.cdr.markForCheck();
            });

            this.qualificationsSubscription = this.qualificationService.qualificationsGet().subscribe((qualifications) => {
                this.qualifications = qualifications;
                this.cdr.markForCheck();
            });

            this.experienceLevelSubscription = this.experienceLevelService.experienceLevelsGet().subscribe((expLevels) => {
                this.experienceLevels = expLevels;
                this.cdr.markForCheck();
            });
        }
    }


    ngOnDestroy(): void {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.employeeQualificationsSubscription?.unsubscribe();
        this.qualificationsSubscription?.unsubscribe();
    }

    enableEditMode() {
        this.editMode = true;
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.cdr.markForCheck();
    }


    canEdit(employee: EmployeeDto): boolean {
        return this.currentEmployeeService.canEditCurrentEmployee(this?.currentUser, employee) && !this.editMode;
    }

    createEmployeeQualificationDtos(employeeQualificationDtos: Array<EmployeeQualificationSummaryDto>): Array<EmployeeQualificationUpsertDto> {
        let returnValue: Array<EmployeeQualificationUpsertDto> = [];
        for (const employeeQualificationDto of employeeQualificationDtos) {
            const upsertDto = new EmployeeQualificationUpsertDto({
                UserID: employeeQualificationDto.UserID,
                QualificationID: employeeQualificationDto.Qualification.QualificationID,
                ExperienceLevelID: employeeQualificationDto.ExperienceLevel?.ExperienceLevelID,
            });
            returnValue.push(upsertDto);
        }
        return returnValue;
    }

    add(): void {
        if (!this.selectedQualification) {
            return;
        }
        if (this.selectedQualification.QualificationType.RequiresExperienceLevel && !this.selectedExperienceLevel) {
            return;
        }

        let upsertDto = new EmployeeQualificationUpsertDto({
            UserID: this.employee.UserID,
            QualificationID: this.selectedQualification.QualificationID,
            ExperienceLevelID: this.selectedExperienceLevel?.ExperienceLevelID
        });
        this.employeeQualificationUpsertDtos.push(upsertDto);

        this.employeeQualificationDtos.push({
            Qualification: this.selectedQualification,
            ExperienceLevel: this.selectedExperienceLevel
        });
        this.selectedQualification = null;
        this.selectedExperienceLevel = null;
        this.currentQualification.set('');
    }

    remove(qualification: EmployeeQualificationSummaryDto): void {
        let upsertDto = this.employeeQualificationUpsertDtos.find(q => q.QualificationID === qualification.Qualification.QualificationID && q.ExperienceLevelID === qualification.ExperienceLevel?.ExperienceLevelID);

        if (qualification && upsertDto) {
            this.employeeQualificationDtos.splice(this.employeeQualificationDtos.indexOf(qualification), 1);
            this.employeeQualificationUpsertDtos.splice(this.employeeQualificationUpsertDtos.indexOf(upsertDto), 1);
        }

        this.cdr.markForCheck();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedQualification = this.qualifications.find(q => q.QualificationID === event.option.value.QualificationID)
        this.selectedExperienceLevel = null;
    }

    getName(qualification: QualificationDto) {
        return qualification?.Name;
    }

    save() {
        this.employeeQualificationService.employeesUserIDQualificationsPut(this.employee.UserID, this.employeeQualificationUpsertDtos).subscribe((_) => {
            this.editMode = false;
            this.refreshData();
            this.cdr.detectChanges();
        }, (error) => {
            console.error(error);
        });
    }

    cancelEditMode() {
        this.editMode = false;
        this.refreshData();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.originalEmployeeQualificationUpsertDtos) === JSON.stringify(this.employeeQualificationUpsertDtos);
        } else {
            return true;
        }
    }
}
