<div class="list-view">
  <div class="page-header">
    <div class="actions-bar flex-between">
      <h1 class="page-title">Projects</h1>
      @if (canCreateProject) {
        <esa-material-button label="Project" icon="add" type="primary" (click)="navigateToProjectCreatePage()">
        </esa-material-button>
      }
    </div>
  </div>
  <mat-accordion>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> Projects Map </mat-panel-title>
      </mat-expansion-panel-header>

      <map #MapComponent (onMapLoad)="mapInit($event)">
        @if (map) {
          @for (p of filteredProjectsWithGeoData; track $index) {
            <map-marker 
              #mapMarker
              [latitude]="p.Latitude" 
              [longitude]="p.Longitude" 
              [label]="p.Name" 
              [map]="map"
              (onClick)="onMarkerClick($event, p)">
            </map-marker>
          } 
        }
      </map>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="filter-btn-group align-right mb-3">
    <talentbridge-clear-grid-filters-button class="mr-2" [grid]="projectsGrid"></talentbridge-clear-grid-filters-button>
    <talentbridge-csv-download-button [grid]="projectsGrid" fileName="projects"></talentbridge-csv-download-button>
  </div>

  <div>
    <div class="table-responsive" style="height: 600px">
      <ag-grid-angular
        #ProjectsGrid
        style="width: 100%; height: 100%"
        class="ag-theme-balham"
        [rowData]="rowData"
        [pinnedBottomRowData]="pinnedBottomRowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [suppressMenuHide]="true"
        rowSelection="single"
        (gridReady)="onProjectsGridReady($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (filterChanged)="onFilterChanged($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
