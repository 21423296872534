<div class="detail-view">
  @if (qualification$ | async; as qual) {
    <div class="actions-bar">
      @if (canEdit(qual)) {
        <esa-material-button type="clear" icon="edit" (click)="enableEditMode()"> </esa-material-button>
      }
    </div>
    <talentbridge-qualification-form
      #updateQualificationForm
      [qualificationID]="qual.QualificationID"
      [qualification]="qual"
      [editMode]="editMode"
      (formSubmitted)="save($event)"
      (cancelEditModeChange)="cancelEdit()">
    </talentbridge-qualification-form>
  }
</div>
