<div class="detail-view">
    
    <div class="filter-btn-group align-right mb-3">
        <talentbridge-clear-grid-filters-button class="mr-2" [grid]="projectEmployeeGrid"></talentbridge-clear-grid-filters-button>
        <talentbridge-csv-download-button [grid]="projectEmployeeGrid" fileName="projectEmployees"></talentbridge-csv-download-button>
    </div>

    <div>
        <div class="table-responsive" style="height: 500px">
            <ag-grid-angular
                #projectEmployeeGrid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [rowData]="rowData"
                [pinnedBottomRowData]="pinnedBottomRowData" 
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                (gridReady)="onProjectEmployeeGridReady($event)"
                (filterChanged)="onFilterChanged($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
