<div class="detail-view">
  @if (subService$ | async; as sub) {
    <div class="actions-bar">
      @if (canEdit(sub)) {
        <esa-material-button type="clear" icon="edit" (click)="enableEditMode()"> </esa-material-button>
      }
    </div>
    <talentbridge-sub-service-form
      #updateSubServiceForm
      [subServiceID]="sub.SubServiceID"
      [subService]="sub"
      [editMode]="editMode"
      (formSubmitted)="save($event)"
      (cancelEditModeChange)="cancelEdit()">
    </talentbridge-sub-service-form>
  }
</div>
