import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { RoleService } from 'src/app/shared/generated/api/role.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Observable, Subscription } from "rxjs";
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { RoleDto, UserInviteDto } from 'src/app/shared/generated/model/models';
import { map } from "rxjs/operators";
import { AsyncPipe } from '@angular/common';
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent } from 'esa-material-form-field';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'talentbridge-user-invite',
    templateUrl: './user-invite.component.html',
    styleUrls: ['./user-invite.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
        EsaMaterialButtonComponent,
        AsyncPipe,
    ],
})
export class UserInviteComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public model: UserInviteDto;
  public roles: Array<RoleDto>;
  public isLoadingSubmit: boolean = false;

  currentUserSubscription: Subscription;
  inviteUserSubscription: Subscription;
  roles$: Observable<RoleDto[]>;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private roleService: RoleService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.cdr.markForCheck();
    });

    this.model = new UserInviteDto();

    this.roles$ = this.roleService.rolesGet().pipe(
        map((data) => {
            const roles = data.sort((a: RoleDto, b: RoleDto) => {
                if (a.DisplayName > b.DisplayName) return 1;
                if (a.DisplayName < b.DisplayName) return -1;
                return 0;
            });
            return roles;
        })
    );
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.currentUserSubscription?.unsubscribe();
    this.inviteUserSubscription?.unsubscribe();
  }

  cancel() {
    this.router.navigateByUrl("manage/users");
  }

  onSubmit(): void {
    this.isLoadingSubmit = true;

    this.inviteUserSubscription = this.userService.usersInvitePost(this.model).subscribe((newUser) => {
      this.isLoadingSubmit = false;
      this.router.navigateByUrl("/manage/users/" + newUser.UserID).then(x => {
        this.alertService.pushAlert(new Alert("The user was successfully invited.", AlertContext.Success));
        this.cdr.markForCheck();
      });
    },
    (error) => {
        this.isLoadingSubmit = false;
        this.cdr.detectChanges();
    });
  }
}