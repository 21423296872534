<div class="site" style="min-height: auto;">
  @if (employee$ | async; as emp) {
    <div class="dashboard">
      <aside class="sidebar">
        <div class="sidebar-header">
          @if (emp.ProfilePhotoBase64) {
            <img [src]="emp.ProfilePhotoBase64" [alt]="emp.FullName" class="profile-photo" />
          }
          
          <h3><mat-icon style="vertical-align: bottom;" fontSet="material-icons-outlined">badge</mat-icon> {{emp.FullName}}</h3>
        </div>
        <div class="sidebar-content">
          <div class="sidebar-content-header">
            <h4>Employee Profile</h4>
          </div>
          <mat-divider style="background-color:#f08b1d; border-width: 1px;"></mat-divider>
          <div class="sidebar-content-body">
            <ul class="sidebar-nav">
              @for (item of employeeSideNav; track item) {
                @if (item.CanShow(emp)) {
                  <li class="sidebar-item">
                    <a [routerLink]="item.Link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidebar-link">{{ item.Name }}</a>
                  </li>
                }
              }
            </ul>
          </div>
        </div>
        <div class="sidebar-footer">
          <div class="md-copy">
            <p>Name: {{ emp.FullName }}</p>
            <p>Job Title: {{ emp.JobTitle }}</p>
            <p>Department: {{ emp.Department }}</p>
            <p>Office Location: {{ emp.OfficeLocation }}</p>
            <p>Manager: {{ emp.Manager?.FullName }}</p>
          </div>
        </div>
      </aside>
      <div class="main">        
        <router-outlet #manageOutlet="outlet"></router-outlet>
      </div>
    </div>
}
</div>
