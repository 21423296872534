import { Component, Input, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { ClientDto } from 'src/app/shared/generated/model/client-dto';
import { EsaMaterialFormFieldComponent } from 'esa-material-form-field';

@Component({
    selector: 'talentbridge-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
    ],
})
export class ClientFormComponent {
  @ViewChild("clientForm", { read: NgForm }) form: NgForm;

  @Input() clientID: number;
  @Input() client: ClientDto;

  constructor() { }
}