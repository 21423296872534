import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FieldDefinitionService } from 'src/app/shared/generated/api/field-definition.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FieldDefinitionDto } from 'src/app/shared/generated/model/models';
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { FormsModule } from '@angular/forms';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'talentbridge-field-definition-edit',
    templateUrl: './field-definition-edit.component.html',
    styleUrls: ['./field-definition-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    EditorComponent,
    FormsModule,
    EsaMaterialButtonComponent,
    AsyncPipe
], 
    providers: [
      { provide: TINYMCE_SCRIPT_SRC, useValue: 'assets/tinymce/tinymce.min.js' }
    ]
})
export class FieldDefinitionEditComponent implements OnInit {
  public fieldDefinition: FieldDefinitionDto;
  public fieldDefinition$: Observable<FieldDefinitionDto>;
  public orignalFieldDefinitionValue: string;
  public editor;

  updatedFieldSubscription: Subscription;
  isLoadingSubmit: boolean;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private alertService: AlertService,
      private fieldDefinitionService: FieldDefinitionService,
      private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    const id = parseInt(this.route.snapshot.paramMap.get("id"));

    if (!id) return;

    this.fieldDefinition$ = this.fieldDefinitionService.fieldDefinitionsFieldDefinitionTypeIDGet(id).pipe(
      map((fieldDefinition) => {
          this.fieldDefinition = fieldDefinition;
          this.orignalFieldDefinitionValue = fieldDefinition.FieldDefinitionValue;
          return fieldDefinition;
      })
    );
  }

  ngOnDestroy() {
      this.cdr.detach();
      this.updatedFieldSubscription?.unsubscribe();
  }

  cancelEditMode() {
    this.router.navigateByUrl("manage/labels-and-definitions");
  }

  canExit(): boolean {
    return this.fieldDefinition.FieldDefinitionValue == this.orignalFieldDefinitionValue;
  }
 
  saveDefinition(): void {
    this.isLoadingSubmit = true;

    this.updatedFieldSubscription = this.fieldDefinitionService.fieldDefinitionsFieldDefinitionTypeIDPut(this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeID, this.fieldDefinition)
      .subscribe((response) => {
        this.isLoadingSubmit = false;
        this.fieldDefinition = response;
        this.orignalFieldDefinitionValue = this.fieldDefinition.FieldDefinitionValue;
        
        this.router.navigateByUrl("/manage/labels-and-definitions").then(x => {
          this.alertService.pushAlert(new Alert(`The definition for ${this.fieldDefinition.FieldDefinitionType.DisplayName} was successfully updated.`, AlertContext.Success));
        });
      },
      (error) => {
        this.isLoadingSubmit = false;
        this.cdr.detectChanges();
      });
  }
}
