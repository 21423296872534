<div style="margin-top: 2rem;">
  <esa-material-button type="primary" label="Select Files" icon="file_open" (click)="fileInput.click()" [disabled]="isLoading"></esa-material-button>

  <input type="file" multiple hidden (change)="onFilesSelected(fileInput.files)" #fileInput [accept]="allowedFileExtensions"/>

  @if (filesToUpload && filesToUpload.length > 0) {
    <div style="margin-top: 2rem;">
      <ul style="margin-bottom: 2rem;">
        @for (file of filesToUpload; track file; let i = $index) {
          <li style="font-size: 16px; height: 32px;">
            <span>{{ file.name }}</span>
            @if (!isLoading) {
              <esa-material-button type="icon" icon="disabled_by_default" (click)="spliceFiles(i)" style="margin-left: 1rem;"></esa-material-button>
            }
          </li>
        }
      </ul>
      @if (isLoading) {
        <mat-spinner style="margin: 1rem;"></mat-spinner>
      }
      <esa-material-button type="primary" label="Upload Files" icon="publish" (click)="uploadFiles()" [disabled]="isLoading"></esa-material-button>
    </div>
  }
</div>