import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { EsaMaterialButtonComponent } from "esa-material-form-field";

@Component({
    selector: "talentbridge-confirm-dialog",
    templateUrl: "confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        EsaMaterialButtonComponent,
        MatDialogClose,
    ],
})
export class ConfirmDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data:any) {}
}