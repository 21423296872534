import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { ClientService } from 'src/app/shared/generated/api/client.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'talentbridge-client-detail',
    templateUrl: './client-detail.component.html',
    styleUrls: ['./client-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
    AsyncPipe
],
})
export class ClientDetailComponent implements OnInit, OnDestroy {
  public client$: Observable<any>;
  public currentUser: UserDto;
  public editMode: boolean = false;
  public clientSideNav: any[] = [];

  editViewButtonClicked: Subscription;
  currentUserSubscription: Subscription;

  constructor(
    private clientService: ClientService,
    private currentClientService: CurrentClientService,
    private authenticationService: AuthenticationService,
    private editViewEventService: EditViewEventService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    const clientID = parseInt(this.route.snapshot.paramMap.get("id"));
    this.clientSideNav = [
      {
        Name: "Summary",
        Link: `/clients/${clientID}`
      },
      {
        Name: "Projects",
        Link: `/clients/${clientID}/projects`
      },
      {
        Name: "Employees",
        Link: `/clients/${clientID}/employees`
      },
    ];
    
    this.clientService.clientsClientIDGet(clientID).subscribe((data) => {
        this.currentClientService.setCurrentClient(data);
      },
        () => {
          this.router.navigate([""]);
        });

    this.client$ = this.currentClientService.getCurrentClient().pipe(
      map((data) => {
        return data;
      })
    );

    this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
      this.currentUser = result;
      this.cdr.markForCheck();
    });

    this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
      this.editMode = result;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.currentClientService.setCurrentClient(null);
    this.currentUserSubscription.unsubscribe();
    this.editViewButtonClicked?.unsubscribe();
  }
}