@if (isLoading) {
  <div class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
  </div>
}

@if (fieldDefinition$ | async; as fieldDefinition) {
  <div class="d-inline">
    <ng-template #popTitle>
      <div class="flex-between">
        {{ fieldDefinition.FieldDefinitionType.DisplayName }}
        @if (showEditButton()) {
          <esa-material-button type="clear" icon="edit" [hidden]="isEditing" (click)="enterEdit()"> </esa-material-button>
        }
      </div>
    </ng-template>
    <ng-template #popContent>
      @if (!isEditing) {
        @if (showEditButton()) {
          <div style="position: relative; min-height: 30px;">
            @if (!emptyContent) {
              <div [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
            }
            @if (emptyContent) {
              <div class="hoverEmptySpace"></div>
            }
          </div>
        }
        @if (!showEditButton()) {
          <div [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
        }
      }
      @if (isEditing) {
        <div>
          <editor #tinyMceEditor
            [init]="{toolbar: false, menubar: false, height:200,width:250, plugins: 'quickbars'}"
            [(ngModel)]="editedContent"
          ></editor>
          <div class="actions-bar" style="margin-top: 1rem;">
            <esa-material-button type="primary" icon="save" label="Save" (click)="saveEdit()" style="margin-right: 1rem"></esa-material-button>
            <esa-material-button type="clear" icon="cancel" label="Cancel" (click)="cancelEdit()"></esa-material-button>
          </div>
        </div>
      }
    </ng-template>
    @if (showEditButton()) {
      <div class="d-inline-block">
        <span style="overflow:hidden; text-overflow:ellipsis">
          @if (!iconOnly) {
            <span>{{ getLabelText() }}</span>
          }
          <mat-icon class="help-icon" [popper]="popContent" [popperTitle]="popTitle" [popperOptions]="popperOptions">help_outline</mat-icon>
        </span>
      </div>
    }
    @if (!showEditButton()) {
      <span>
        @if (!iconOnly) {
          <span>{{ getLabelText() }}</span>
        }
        <mat-icon class="help-icon" [popper]="popContent" [popperTitle]="popTitle" [popperOptions]="popperOptions">help_outline</mat-icon>
      </span>
    }
  </div>
}