<div class="home-splash">
  <div class="photo">
    <img src="../assets/main/talentbridge.jpg" alt="TalentBridge" />
  </div>

  <div class="info">
    @if (userIsUnassigned) {
      <div class="copy copy-2">
        <p>Welcome {{currentUser.FullName}}. You have successfully logged in to the {{platformLongName}},
          but your account is not yet configured. You will receive an email from the Administrators when your account is
          ready to use. You may <a routerLink="/help">Request Support</a> to contact the Administrators with any questions or
        comments.</p>
      </div>
    }

    @if (userRoleIsDisabled) {
      <div>
        <p>
          Your account is currently disabled. Please <a routerLink="/help">Request Support</a> if you would like to
          activate your account.
        </p>
      </div>
    }

    <h1 class="page-title">{{ platformLongName }}</h1>

    <div class="copy copy-2">
      <custom-rich-text [customRichTextTypeID]="richTextTypeID" [userCanEdit]="userCanEditCustomText" editorId="homePageText"></custom-rich-text>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <h3 class="card-title">{{ currentUser ? 'Quick Actions' : 'Access'}}</h3>
      </div>
      <div class="card-body">
        @if (currentUser) {
          <esa-material-button type="primary" label="Request Support" (click)="requestSupport()" data-cy="userSupport"></esa-material-button>
        } @else {
          <div class="copy copy-3">
            <p>Sign in to view your Account.</p>
          </div>
          <div class="mt-2">
            <esa-material-button type="primary" label="Sign In" aria-label="Sign In" (click)="login()" style="margin-right: 1rem;"></esa-material-button>
          </div>
        }
      </div>
    </div>
  </div>
</div>