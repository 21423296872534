import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SubServiceFormComponent } from '../shared/sub-service-form/sub-service-form.component';
import { CurrentSubServiceService } from 'src/app/services/current-sub-service.service';
import { SubServiceDto } from 'src/app/shared/generated/model/sub-service-dto';
import { SubServiceUpsertDto } from 'src/app/shared/generated/model/sub-service-upsert-dto';


@Component({
  selector: 'talentbridge-sub-service-create',
  templateUrl: './sub-service-create.component.html',
  styleUrls: ['./sub-service-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SubServiceFormComponent
]
})
export class SubServiceCreateComponent implements OnInit, OnDestroy {        
  @ViewChild("createNewSubServiceForm", { read: SubServiceFormComponent }) form: SubServiceFormComponent;

  public subService: SubServiceDto = new SubServiceDto();
  public subServiceUpsertDto: SubServiceUpsertDto;
  private subServiceResponse: any;

  editMode = true;

  constructor(
      private currentSubServiceService: CurrentSubServiceService,
      private router: Router,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.subServiceUpsertDto = this.currentSubServiceService.createSubServiceDto(this.subService);
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  save(result): void {
    if (!!result) {
        this.subServiceResponse = result;
        this.router.navigateByUrl(`sub-services/${result.SubServiceID}`);
    } else {
        this.cdr.detectChanges();
    }
  }

  cancelEdit() {
      this.router.navigateByUrl("sub-services");
  }

  canExit() {
      if (this.subServiceResponse) {
          return true;
      } else {
          return JSON.stringify(this.subServiceUpsertDto) === JSON.stringify(this.form.subServiceUpsertDto);
      }
  }
}
