import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { AdminConfigurationService } from 'src/app/shared/generated/api/admin-configuration.service';
import { AdminConfigurationDto } from 'src/app/shared/generated/model/admin-configuration-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
    selector: 'talentbridge-configuration-form',
    templateUrl: './configuration-form.component.html',
    styleUrls: ['./configuration-form.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
        EsaMaterialButtonComponent
    ],
})
export class ConfigurationFormComponent {
  @ViewChild("configurationForm", { read: NgForm }) form: NgForm;

  @Input() adminConfiguration: AdminConfigurationDto;
  @Input() editMode: boolean;

  @Output() cancelEditModeChange = new EventEmitter<boolean>();
  
  constructor(
    private configurationService: AdminConfigurationService,
    private alertService: AlertService
  ) { }

  saveForm(form: NgForm) {
      this.configurationService.adminConfigurationPut(this.adminConfiguration)
        .subscribe((result) => {
          this.cancelEditMode();
          this.alertService.pushAlert(new Alert("The configuration was successfully updated.", AlertContext.Success), 5000);
        });
  }

  cancelEditMode() {
    this.cancelEditModeChange.emit(true);
  }

}