<div class="detail-view">
    <div class="page-header pb-3">
        <h1 class="page-title">New Sub-Service</h1>
        <div class="g-col-6" style="display: flex; flex-direction: column; justify-content: center">
            <p>
                This page allows you to create a new sub-service.
            </p>
        </div>
    </div>

    <talentbridge-sub-service-form
        #createNewSubServiceForm
        [subService]="subService"
        [editMode]="editMode"
        (formSubmitted)="save($event)"
        (cancelEditModeChange)="cancelEdit()">
    </talentbridge-sub-service-form>
</div>
