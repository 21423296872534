//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Permission]

export enum PermissionEnum {
  CustomPageRights = 1,
  CustomRichTextRights = 2,
  FieldDefinitionRights = 3,
  UserRights = 4,
  ProjectRights = 5,
  EmployeeRights = 6,
  QualificationRights = 7,
  ClientRights = 8,
  AdminConfigurationRights = 9,
}
