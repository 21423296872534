<div class="list-view">
  <div class="page-header">
    <div class="actions-bar flex-between">
      <h1 class="page-title">Sub-Services</h1>
      @if (canCreate) {
        <esa-material-button label="Sub Service" icon="add" type="primary" (click)="navigateToSubServiceCreatePage()">
        </esa-material-button>
      }
    </div>

  </div>

  <div class="filter-btn-group align-right mb-3">
    <talentbridge-clear-grid-filters-button class="mr-2" [grid]="subServicesGrid"></talentbridge-clear-grid-filters-button>
    <talentbridge-csv-download-button [grid]="subServicesGrid" fileName="subServices"></talentbridge-csv-download-button>
  </div>

  <div class="table-responsive" style="height: 800px;">
    <ag-grid-angular
      #subServicesGrid
      style="width: 100%; height: 100%;"
      class="ag-theme-balham"
      [rowData]="rowData"
      [pinnedBottomRowData]="pinnedBottomRowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [suppressMenuHide]="true"
      (gridReady)="onSubServicesGridReady($event)"
      (filterChanged)="onFilterChanged($event)">
    </ag-grid-angular>
  </div>
</div>
