
@if (params.value.LinkDisplay && !params.value.ChildRoute) {

  @if (params.value.ImageSrc) {
    <a
      [routerLink]="[params.inRouterLink, params.value.LinkValue]"
      [className]="params.value.CssClasses"><img [src]="params.value.ImageSrc" [alt]="params.value.LinkDisplay" /></a>
  }
  <a
    [routerLink]="[params.inRouterLink, params.value.LinkValue]"
    [className]="params.value.CssClasses">{{ params.value.LinkDisplay }}</a>
}
@if (params.value.LinkDisplay && params.value.ChildRoute) {
 
  @if (params.value.ImageSrc) { 
    <a
      [routerLink]="[params.inRouterLink, params.value.LinkValue, params.value.ChildRoute]"
      [queryParams]="params.value.QueryParams"
      [className]="params.value.CssClasses"><img [src]="params.value.ImageSrc" [alt]="params.value.LinkDisplay" /></a>
  }
  <a
    [routerLink]="[params.inRouterLink, params.value.LinkValue, params.value.ChildRoute]"
    [queryParams]="params.value.QueryParams"
    [className]="params.value.CssClasses">{{ params.value.LinkDisplay }}</a>
}
