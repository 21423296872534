import {
    Component,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    ViewChild,
} from "@angular/core";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { ColDef } from "ag-grid-community";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { AgGridAngular } from "ag-grid-angular";

import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";

import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";

import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { ClearGridFiltersButtonComponent } from "src/app/shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { CsvDownloadButtonComponent } from "src/app/shared/components/csv-download-button/csv-download-button.component";


@Component({
    selector: "talentbridge-qualification-list",
    templateUrl: "./qualification-list.component.html",
    styleUrls: ["./qualification-list.component.scss"],
    standalone: true,
    imports: [
    AgGridAngular,
    EsaMaterialButtonComponent,
    ClearGridFiltersButtonComponent,
    CsvDownloadButtonComponent
]
})
export class QualificationListComponent implements OnInit, OnDestroy {
    @ViewChild("qualificationsGrid") qualificationsGrid: AgGridAngular;

    private currentUser: UserDto;

    public canCreate: boolean;

    public rowData = [];
    columnDefs: ColDef[];
    public gridComponents: any;
    public defaultColDef: any;
    pinnedBottomRowData;

    public isPerformingAction: boolean = false;

    userSubscription: Subscription;
    qualificationSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private qualificationService: QualificationService,
        private router: Router,
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
          };
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;

                this.canCreate = this.authenticationService.hasPermission(
                    this.currentUser,
                    PermissionEnum.QualificationRights,
                    RightsEnum.Create
                );

                this.cdr.markForCheck();

                this.createColumnDefs();
            });
    }

    createColumnDefs() {
        this.columnDefs = [
            {
                headerName: "Service Line",
                field: "SubService.ServiceCategory.ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                            field: "SubService.ServiceCategory.ServiceLine.Name",
                },
                tooltipField: "SubService.ServiceCategory.ServiceLine.Name",
            },
            {
                headerName: "Service Category",
                field: "SubService.ServiceCategory.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "SubService.ServiceCategory.Name",
                },
                tooltipField: "SubService.ServiceCategory.Name",
            },
            {
                headerName: "Sub-Service",
                field: "SubService.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "SubService.Name",
                },
                tooltipField: "SubService.Name",
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return params.node.rowPinned
                    ? "Total: " + params.data.QualificationTotal
                    : {
                        LinkValue: params.data.QualificationID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return params.node.rowPinned
                        ? {
                            component: CustomPinnedRowRendererComponent,
                            params:  { filter: true },
                          }
                        : {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "./" },
                          };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA: any, linkB: any) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 3,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
            {
                headerName: "Employee Count",
                valueGetter: function (params: any) {
                    return params.node.rowPinned
                        ? "Total: " + params.data.EmployeeTotal
                        : params.data.EmployeeCount;
                },
                cellRendererSelector: (params) => {
                    return {
                        component: CustomPinnedRowRendererComponent,
                        params:  { filter: true },
                    };
                },
                filter: "agNumberColumnFilter",
                flex: 2,
                tooltipField: "EmployeeCount",
                cellDataType: "number" 
            },
            {
                headerName: "Type",
                field: "QualificationType.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "QualificationType.Name",
                },
                tooltipField: "QualificationType.Name",
            },
        ];
    }

    onQualificationsGridReady(gridEvent) {
        this.qualificationsGrid?.api.showLoadingOverlay();
        this.updateGridData();
    }

    onFilterChanged(gridEvent) {
        gridEvent.api.setGridOption("pinnedBottomRowData", [
            {
                QualificationTotal: gridEvent.api.getDisplayedRowCount(),
                EmployeeTotal: gridEvent.api.getModel().rowsToDisplay.map(x=>x.data.EmployeeCount).reduce((sum, x) => sum + x, 0)
            },
        ]);
    }

    navigateToQualificationCreatePage() {
        this.router.navigateByUrl("qualifications/create");
    }

    public updateGridData(): void {
        this.qualificationSubscription = this.qualificationService
            .qualificationsGet()
            .subscribe((qualifications) => {
                this.rowData = qualifications.map((qual) => {
                    qual["$NameAndDescription"] = qual.Description ? `${qual.Name}: ${qual.Description}` : qual.Name;
                    return qual;
                });
                this.pinnedBottomRowData = [
                    {
                        QualificationTotal: this.rowData.length,
                        EmployeeTotal: this.rowData.map(x => x.EmployeeCount).reduce((sum, x) => sum + x, 0)
                    }
                ]
                this.qualificationsGrid.api.hideOverlay();
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.qualificationSubscription?.unsubscribe();
    }
}
