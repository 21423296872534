@if (user$ | async; as user) {
  <div class="detail-view">
    <div class="page-header">
      <h1 class="page-title">
        {{ user?.FullName || ((user?.FirstName || user?.LastName) ? (user?.FirstName + " " + user?.LastName) : null) }}
      </h1>
    </div>
    <div class="card card-55 mt-3">
      <div class="card-header flex-justify-between">
        <h3 class="card-title">Basics</h3>
        <div class="card-actions">
          @if (canUpdateUser()) {
            <esa-material-button type="primary" icon="edit" label="Edit" (click)="editUser()"></esa-material-button>
          }
          @if (canImpersonate()) {
            <esa-material-button type="primary" [disabled]="!user.UserGuid" icon="person" label="Impersonate" (click)="impersonateUser(user?.UserID)"> </esa-material-button>
          }
        </div>
      </div>
      <div class="grid-12">
        <div class="g-col-12">
          <div class="kv-pair">
            <div class="key"><label>Username</label></div>
            <div class="value">
              {{ user?.LoginName }}
              @if (!user?.LoginName) {
                <em class="text-muted">Not Available</em>
              }
            </div>
          </div>
          <div class="kv-pair">
            <div class="key"><label>First Name</label></div>
            <div class="value">
              {{ user?.FirstName }}
              @if (!user?.FirstName) {
                <em class="text-muted">Not Available</em>
              }
            </div>
          </div>
          <div class="kv-pair">
            <div class="key"><label>Last Name</label></div>
            <div class="value">
              {{ user?.LastName }}
              @if (!user?.LastName) {
                <em class="text-muted">Not Available</em>
              }
            </div>
          </div>
          <div class="kv-pair">
            <div class="key"><label>Full Name</label></div>
            <div class="value">
              {{ user?.FullName }}
              @if (!user?.FullName) {
                <em class="text-muted">Not Available</em>
              }
            </div>
          </div>
          <div class="kv-pair">
            <div class="key"><label>Email</label></div>
            <div class="value">
              {{ user?.Email }}
              @if (!user?.Email) {
                <em class="text-muted">Not Available</em>
              }
            </div>
          </div>
          <div class="kv-pair">
            <div class="key"><label>Role</label></div>
            <div class="value">
              {{ user?.Role?.DisplayName }}
              @if (!user?.Role?.DisplayName) {
                <em class="text-muted">Not Available</em>
              }
            </div>
          </div>
          <div class="kv-pair">
            @if (user.Role.RoleID === 1) {
              <div class="key"><label>Receives System Communications</label></div>
            }
            @if (user.Role.RoleID === 1) {
              <div class="value">
                {{ user?.ReceiveSupportEmails == true ? "Yes" : "No" }}
                @if (!user) {
                  <em class="text-muted">Not Available</em>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}