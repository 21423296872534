import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { ClientDto } from '../shared/generated/model/client-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
    providedIn: 'root'
})
export class CurrentClientService {
    currentClient$ = new BehaviorSubject<ClientDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentClient(client: ClientDto): void {
        this.currentClient$.next(client);
    }

    getCurrentClient(): Observable<any> {
        return this.currentClient$.asObservable();
    }

    canEditCurrentClient(currentUser: UserDto, client: ClientDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.ClientRights, RightsEnum.Update);
    }
}
