import { Component, Input, OnInit } from "@angular/core";
import { FieldDefinitionComponent } from "../field-definition/field-definition.component";


@Component({
    selector: "talentbridge-custom-form-label",
    templateUrl: "./custom-form-label.component.html",
    styleUrls: ["./custom-form-label.component.scss"],
    standalone: true,
    imports: [FieldDefinitionComponent],
})
export class CustomFormLabelComponent implements OnInit {
    @Input() label!: string;
    @Input() fieldDefinitionType!: string;
    @Input() popperOptions!: any;

    constructor() {}

    ngOnInit(): void {}
}