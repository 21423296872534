import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';

import { MsalService } from '@azure/msal-angular';

import { DatadogService } from './app/shared/services/datadog.service';
import { AppInsightsService } from './app/shared/services/app-insights.service';
import { appConfig } from './app/app.config'


declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

export function init_app(appInsightsService: AppInsightsService,  datadogService: DatadogService, msalService: MsalService) {
    return () => {
        if (environment.appInsightsInstrumentationKey) {
            appInsightsService.initAppInsights();
        }
        
        if (environment.datadogClientToken) {
          datadogService.init();
        }
        
        
    };
}
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig )
  .catch(err => console.error(err));
